import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Children, FC, ReactNode } from "react";
import { createUseStyles } from "react-jss";
import { Theme } from "../theme/theme";

interface IProps {
  loading: boolean;
  emptyIcon?: ReactNode;
  emptyDescription?: string;
  className?: string;
  isEmpty?: boolean;
}

const useStyles = createUseStyles<string, unknown, Theme>(theme => {
  return {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      minHeight: "min(100vh, 300px)",
    },
    empty: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    emptyIcon: {
      fontSize: 34,
      color: theme.fontColorAccented,
    },
  };
});

const Loading: FC<IProps> = props => {
  const { children, loading, emptyIcon, emptyDescription, isEmpty, className } = props;
  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.container}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} />} />
      </div>
    );
  }

  if (isEmpty || (Children.count(children) === 0 && (emptyIcon || emptyDescription))) {
    return (
      <div className={classes.container}>
        <div className={classes.empty}>
          <span className={classes.emptyIcon}>{emptyIcon}</span>
          <span>{emptyDescription}</span>
        </div>
      </div>
    );
  }

  return <div className={className}>{children}</div>;
};

export default Loading;
