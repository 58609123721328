import React, { FC, useEffect, useMemo, useState } from "react";

import { farmingOperation } from "../../../api/farming";
import { TnType } from "../../../constants/tnType";
import { formatDecimal, formatPercentage } from "../../../helpers/format";
import { gt, lt, lte, sub } from "../../../helpers/numbers";
import { hashFarmingRequest } from "../../../helpers/requestHashers";
import { IValidator, useTokenInputState } from "../../../helpers/tokenAmountState";
import { useTransitionOperator } from "../../../hooks/operation/transitionOperator";
import { FarmingPool } from "../../../redux/farmingPoolSlice";
import LabelWithPopover from "../../LabelWithPopover";
import { FarmingAPY } from "../../StrategyAPY";
import { ITokenInputChangeEvent } from "../../TokenInput";
import ActionModal from "../common/ActionModal";
import ActionTitle from "../common/ActionTitle";
import ModalExtraInfoRow from "../common/ModalExtraInfoRow";
import ModalResult from "../common/ModalResult";
import ModalTokenInput from "../common/ModalTokenInput";
import Info from "./Info";

interface IProps {
  pool: FarmingPool;
  visible: boolean;
  onClose: (shouldReload?: boolean) => void;
}

const StakeModal: FC<IProps> = props => {
  const { pool, visible, onClose } = props;

  const [actualStakeAmount, setActualStakeAmount] = useState("");

  const validators: IValidator[] = useMemo(
    () => [
      {
        validator: amtBig => lte(amtBig, pool.myShareAmt),
        message: `Insufficient ${pool.shareName} balance`,
      },
      {
        validator: amtBig => gt(amtBig, pool.stakeFee.amount),
        message: "Please enter an amount larger than the fee.",
      },
    ],
    [pool],
  );
  const [amount, setAmount, resetInput] = useTokenInputState(pool.shareDecimal, undefined, validators);

  const { operation, signAndOperate, resetOperationState } = useTransitionOperator(
    farmingOperation,
    hashFarmingRequest,
  );

  useEffect(() => {
    const actualAmount = sub(amount.big, pool.stakeFee.amount); // stake amount - stake fee = actual stake
    setActualStakeAmount(actualAmount.toString());
  }, [amount, pool.shareDecimal, pool.stakeFee.amount]);

  const handleStake = async () => {
    signAndOperate({
      type: TnType.Stake,
      poolId: pool.id,
      fee: pool.stakeFee.amount || "0",
      shares: amount.big.toString(),
      timestamp: Date.now(),
      signature: "",
    });
  };

  const handleChange = (e: ITokenInputChangeEvent) => {
    setAmount(e.value);
  };

  const handleModalClose = () => {
    resetOperationState();
    resetInput();
    onClose(operation.completed);
  };
  const assetNames = pool.farmingAssets.map(asset => asset.assetSymbol).join(" and ");
  return (
    <ActionModal
      visible={visible}
      title={<ActionTitle title="Stake" />}
      actionText={operation.completed ? "OK" : "Stake"}
      actionDisabled={!amount.input}
      errMsg={amount.error}
      onAction={operation.completed ? handleModalClose : handleStake}
      onCancel={handleModalClose}
      actionLoading={operation.loading}
      extra={[
        <ModalExtraInfoRow key={1} left="Strategy Name" right={pool.strategyName} />,
        <ModalExtraInfoRow
          key={2}
          left={
            <LabelWithPopover label="Farming APY">
              <FarmingAPY farmingAssets={pool.farmingAssets} />
            </LabelWithPopover>
          }
          right={formatPercentage(pool.apy, false)}
        />,
        <Info.FeeInfo pool={pool} />,
        <ModalExtraInfoRow
          key={4}
          left={
            <LabelWithPopover label="Actual Stake Amount">
              This is your actual stake amount after the fee is deducted.
            </LabelWithPopover>
          }
          right={`${lt(actualStakeAmount, 0) ? 0 : formatDecimal(actualStakeAmount, pool.shareDecimal)} ${
            pool.shareName
          }`}
        />,
      ]}
    >
      {operation.completed ? (
        <ModalResult title="Stake Success" />
      ) : (
        <ModalTokenInput
          description={`Deposit your ${pool.shareName} to earn ${assetNames}.`}
          amount={amount.input}
          maxAmount={formatDecimal(pool.myShareAmt, pool.shareDecimal)}
          symbol={pool.shareName}
          onChange={handleChange}
        />
      )}
    </ActionModal>
  );
};

export default StakeModal;
