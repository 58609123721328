/* eslint-disable react/jsx-props-no-spreading */
import Icon from "@ant-design/icons";
import { FC } from "react";
import { AngleDownSVG, ClockSVG, MiningSVG, SearchSVG } from "../assets/customIcons";

const getRotation = (props: IDirectionProps) => {
  switch (props.direction) {
    case "up":
      return 180;
    case "left":
      return 90;
    case "right":
      return -90;
    default:
      return 0;
  }
};

export interface IDirectionProps {
  direction: "up" | "down" | "left" | "right";
}

export interface CustomIconComponentProps {
  width?: string | number;
  height?: string | number;
  fill?: string;
  viewBox?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const ClockCustom: FC<CustomIconComponentProps> = props => {
  return <Icon component={ClockSVG} {...props} className={props.className} />;
};

export const MiningCustom: FC<CustomIconComponentProps> = props => {
  return <Icon component={MiningSVG} {...props} className={props.className} />;
};

export const SearchCustom: FC<CustomIconComponentProps> = props => {
  return <Icon component={SearchSVG} {...props} className={props.className} />;
};

export const AngleCustom: FC<CustomIconComponentProps & IDirectionProps> = props => {
  return <Icon component={AngleDownSVG} {...props} className={props.className} rotate={getRotation(props)} />;
};
