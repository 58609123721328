import { LoadingOutlined } from "@ant-design/icons";
import { BigNumber } from "@ethersproject/bignumber";
import { Avatar, Button, Col, Row, Spin, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Link, useHistory } from "react-router-dom";
import { useToggle } from "react-use";
import { components } from "../api/api";
import tokens from "../constants/tokens";
import { formatDecimal, formatPercentage } from "../helpers/format";
import { lte } from "../helpers/numbers";
import { getTotalAPY } from "../helpers/strategy";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import { ModalName, openModal } from "../redux/modalSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { fetchStrategies } from "../redux/strategySlice";
import { Media } from "../theme";
import { Theme, ThemeType } from "../theme/theme";
import ContentList from "./ContentList";
import { MiningCustom } from "./customIcons";
import CustomTable from "./CustomTable";
import Gain from "./Gain";
import IconTag from "./IconTag";
import LabelWithPopover from "./LabelWithPopover";
import { ActionModal, ExitModal, FundModal } from "./modals";
import ActionTitle from "./modals/common/ActionTitle";
import StrategyAPYTooltip from "./StrategyAPYTooltip";
import TitleSubtitle from "./TitleSubtitle";
import TokenGain from "./TokenGain";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    "@global": {
      ".ant-table-row": {
        height: 83, // because some strategy has second row to show farming button
      },
    },
  },
  listHeader: {
    display: "flex",
    fontSize: theme.fontSizeM,
    padding: theme.type === ThemeType.S ? "16px 0" : 24,
  },
  actions: {
    display: theme.type === ThemeType.S ? undefined : "flex",
    justifyContent: theme.type === ThemeType.S ? undefined : "flex-end",
    "@global": {
      ".ant-btn": {
        fontSize: theme.fontSizeM,
      },
    },
  },
  actionPending: {
    display: "flex",
    flexDirection: theme.actflexDirection,
    justifyContent: theme.invlexDirection,
    alignItems: theme.actalignItems,
    margin: "-12px 0",

    "@global": {
      ".ant-btn.ant-btn-link": {
        padding: 0,
        height: "auto",
      },
    },
  },
  titleSecondRow: {
    minHeight: 18,
  },
  protocolHeader: {
    fontSize: theme.fontSizeS,
    color: theme.inverseFontColorSecondary,
    marginBottom: 4,
  },
  protocols: {
    display: "flex",
    flexWrap: "nowrap",
  },
  protocol: {
    marginRight: 12,
    color: theme.inverseFontColorSecondary,
  },
  table: {
    margin: "0 -14px",
    padding: "0 24px 24px 24px",
  },
  highlight: theme.highlightedText,
  countdown: {
    color: theme.fontColorTertiary,
  },
}));

const useFarmingButtonStyle = createUseStyles<string, unknown, Theme>(theme => {
  return {
    farmingButton: {
      color: theme.fontColorAccented,
      display: "inline-flex",
      margin: "4px 0 0 -2px",
      fontSize: 12,

      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    [Media.S]: {
      farmingButton: {
        alignItems: "center",
        justifyContent: "center",
        padding: "8px 0",
        display: "flex",
        border: "1px solid " + theme.fontColorAccented,
        borderRadius: 18,
        margin: "12px 0",
      },
    },
  };
});

const getStringComparator = (path: string[]) => (a, b) => {
  let valA = a;
  path.forEach(el => {
    valA = valA[el];
  });
  let valB = b;
  path.forEach(el => {
    valB = valB[el];
  });
  if (valA < valB) return -1;
  if (valA > valB) return 1;
  return 0;
};

const curveTokenSwapTexts = {
  DAI: "USDC and USDT",
  USDC: "DAI and USDT",
  USDT: "DAI and USDC",
};

interface IFarmingButtonProps {
  farmingAssets?: components["schemas"]["RewardAsset"][];
}

const FarmingButton: FC<IFarmingButtonProps> = props => {
  const { farmingAssets } = props;
  const classes = useFarmingButtonStyle();
  const theme = useTheme<Theme>();
  const history = useHistory();

  const goToFarmingPage = useCallback(() => {
    history.push("./farming");
  }, [history]);

  return farmingAssets?.length ? (
    <div className={classes.farmingButton} onClick={goToFarmingPage}>
      <MiningCustom style={{ verticalAlign: "text-top", fontSize: 16 }} /> <span>Farming</span>
      <Avatar.Group style={{ marginLeft: 8 }}>
        {farmingAssets.map(a => (
          <Avatar
            key={a.assetId}
            src={tokens[a.assetSymbol].iconUrl}
            size={18}
            style={{ border: "1px solid " + theme.bgColorSecondary }}
          >
            {!a.assetIconUrl && a.assetSymbol}
          </Avatar>
        ))}
      </Avatar.Group>
    </div>
  ) : null;
};

export default function StrategyList(): JSX.Element {
  const theme = useTheme<Theme>();
  const isMobile = theme.type === ThemeType.S;
  const classes = useStyles();
  const { signer, address } = useWeb3Context();
  const dispatch = useAppDispatch();
  const [showFund, toggleFund] = useToggle(false);
  const [showExit, toggleExit] = useToggle(false);
  const [selectedStrategy, setSelectedStrategy] = useState<components["schemas"]["Strategy"]>({});
  const [showCurveWarning, setShowCurveWarning] = useState(false);
  const { asset, strategy } = useAppSelector(state => state);
  const { assets, selectedIndex } = asset;
  const { strategies, loading, sneakyLoading } = strategy;
  const token = assets[selectedIndex];

  useEffect(() => {
    dispatch(fetchStrategies({ address, tokenId: token.id }));
  }, [address, token.id, dispatch]);

  useEffect(() => {
    const show = !!selectedStrategy.protocols?.filter(protocol => protocol.name === "Curve").length;
    setShowCurveWarning(show);
  }, [selectedStrategy]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showFund]);

  const handleOpenProviderModal = useCallback(() => {
    dispatch(openModal(ModalName.provider));
  }, [dispatch]);

  const renderActions = useCallback(
    (strat: components["schemas"]["Strategy"]) => {
      if (!address) {
        // Not connected
        return (
          <div className={classes.actions}>
            <Button type="primary" onClick={handleOpenProviderModal}>
              Connect Wallet
            </Button>
          </div>
        );
      }

      if (strat.available) {
        return (
          <div className={classes.actions}>
            <Row gutter={8}>
              <Col span={12}>
                <Button
                  block
                  size={isMobile ? "large" : "middle"}
                  type="primary"
                  disabled={!signer}
                  onClick={() => {
                    setSelectedStrategy(strat);
                    toggleFund();
                  }}
                >
                  Deposit
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  block
                  size={isMobile ? "large" : "middle"}
                  type={isMobile ? "primary" : "text"}
                  disabled={!signer || lte(strat.shareAmt, 0)}
                  onClick={() => {
                    toggleExit();
                    setSelectedStrategy(strat);
                  }}
                >
                  Withdraw
                </Button>
              </Col>
            </Row>
          </div>
        );
      }
      // Not available
      return (
        <div className={classes.actionPending}>
          <div style={{ marginLeft: -6 }}>
            <LabelWithPopover label="Aggregation Pending" iconOnLeft placement="left">
              Your fund allocation intent is confirmed on L2 and Layer2.Finance is waiting for the next aggregated fund
              allocation execution.
            </LabelWithPopover>
          </div>
          <div>
            <Button type="link" key="button1">
              <Link to="/history/investment">Check History</Link>
            </Button>
          </div>
        </div>
      );
    },
    [address, classes, handleOpenProviderModal, signer, toggleExit, toggleFund, isMobile],
  );

  const columns = useMemo(() => {
    const _columns: ColumnsType<components["schemas"]["Strategy"]> = [
      {
        title: "Strategy",
        dataIndex: "title",
        key: "title",
        render: (_, strat) => {
          return (
            <TitleSubtitle
              title={
                <LabelWithPopover label={strat.name} placement="topLeft">
                  <div className={classes.protocolHeader}>Defi Protocols Used:</div>
                  <div className={classes.protocols}>
                    {strat.protocols?.map(protocol => (
                      <IconTag key={protocol.name} className={classes.protocol} iconUrl={protocol.iconUrl || ""}>
                        {protocol.name}
                      </IconTag>
                    ))}
                  </div>
                </LabelWithPopover>
              }
              subtitle={isMobile ? null : <FarmingButton farmingAssets={strat.farmingAssets} />}
            />
          );
        },
        sorter: getStringComparator(["title", "name"]),
      },
      {
        title: "TVL",
        dataIndex: "tvl",
        key: "tvl",
        filterIcon: "",
        render: (_, strat) => {
          return `${formatDecimal(strat.tvl, token.decimal, 0)} ${token.symbol}`;
        },
        sorter: getStringComparator(["tvl"]),
      },
      {
        title: "APY",
        dataIndex: "apy",
        key: "apy",
        render: (_, strat) => {
          return (
            <StrategyAPYTooltip
              strategy={strat}
              label={<Gain type={strat.apy}>{formatPercentage(getTotalAPY(strat), false)}</Gain>}
            />
          );
        },
        sorter: getStringComparator(["apy"]),
      },
      {
        title: (
          <LabelWithPopover label="Balance" iconOnLeft={!isMobile}>
            This is your estimated asset value locked in each strategy and the corresponding farming session. Note that
            the value is estimated based on your current stToken balance/price and may not be accurate.
          </LabelWithPopover>
        ),
        dataIndex: "balance",
        key: "balance",
        render: (_, strat) => {
          const shareBalance = BigNumber.from(strat.shareEstimatedValue || "0");
          const stakedBalance = BigNumber.from(strat.stakedEstimatedValue || "0");
          const totalBalance = shareBalance.add(stakedBalance);
          if (totalBalance.lte(0)) {
            return "--";
          }
          if (!strat.farmingAssets?.length) {
            return formatDecimal(shareBalance, token.decimal, 6);
          }
          return (
            <LabelWithPopover label={`${formatDecimal(totalBalance, token.decimal, 6)} ${token.symbol}`}>
              <div>
                Locked in Strategy: {formatDecimal(shareBalance, token.decimal, 6)} {token.symbol}
              </div>
              <div>
                Staked in Farming: {formatDecimal(stakedBalance, token.decimal, 6)} {token.symbol}
              </div>
            </LabelWithPopover>
          );
        },
        sorter: getStringComparator(["balance", "tokenAmt"]),
      },
      {
        title: (
          <LabelWithPopover label="Earning" iconOnLeft={!isMobile}>
            <p>
              Strategy Earning = Current strategy balance - Total deposit to this strategy + Total withdrawal from this
              strategy
            </p>
            <p>
              <b>Note 1:</b> Earning does not include farming rewards which are distributed separately.
            </p>
            <p>
              <b>Note 2:</b> Earning may be negative due to inherent strategy APY fluctuation, staking/unstaking fee,
              etc.
            </p>
            <p>
              <b>Note 3:</b> Earning is updated every {process.env.REACT_APP_EARNING_UPDATE_INTERVAL}.
            </p>
          </LabelWithPopover>
        ),
        dataIndex: "earning",
        key: "earning",
        render: (_, strat) => {
          let amount = BigNumber.from(strat.earnings || "0");
          // avoid backend earning calculation rounding issue aka "-1" earning
          if (amount.gte(-5) && amount.lt(0)) {
            amount = BigNumber.from(0);
          }
          return <TokenGain formattedAmount={formatDecimal(amount, token.decimal)} symbol={token.name} />;
        },
        sorter: getStringComparator(["earning", "tokenAmt"]),
      },
    ];
    if (!isMobile || signer) {
      _columns.push({
        title: "",
        dataIndex: "actions",
        key: "actions",
        render: (_, strat) => {
          return (
            <div>
              {isMobile && (
                <div>
                  <FarmingButton farmingAssets={strat.farmingAssets} />
                </div>
              )}
              {renderActions(strat)}
            </div>
          );
        },
      });
    }
    return _columns;
  }, [classes, token, renderActions, isMobile, signer]);

  return (
    <div className={classes.container}>
      <div className={classes.listHeader}>
        <h3>Investment Strategies</h3>
        {sneakyLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 12 }} />} style={{ marginLeft: 12 }} />}
      </div>
      {!isMobile ? (
        <CustomTable columns={columns} dataSource={strategies} loading={loading} className={classes.table} />
      ) : (
        <ContentList dataList={strategies} columns={columns} keyPropName="id" />
      )}
      {showFund && showCurveWarning && (
        <ActionModal
          title={<ActionTitle title={`Deposit to ${selectedStrategy.name}`} />}
          onAction={() => setShowCurveWarning(false)}
          onCancel={() => toggleFund()}
          visible
        >
          <p>
            <Typography.Text>
              This strategy involves swapping your token to {curveTokenSwapTexts[token.symbol || ""]} in Curve 3Pool and
              provide liquidity and yield mining $CRV token.
            </Typography.Text>
          </p>
          <p>
            <Typography.Text>
              Due to inherent slippage and fluctuation of the underlying stable token price,{" "}
              <span className={classes.highlight}>your earning might be negative</span> for a certain period of time.
            </Typography.Text>
          </p>
        </ActionModal>
      )}
      {showFund && !showCurveWarning && <FundModal token={token} strategy={selectedStrategy} onClose={toggleFund} />}
      {showExit && <ExitModal token={token} strategy={selectedStrategy} onClose={toggleExit} />}
    </div>
  );
}
