import { formatUnits } from "ethers/lib/utils";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { FarmingPoolStatus } from "../api/farming";
import { FarmingPool } from "../redux/farmingPoolSlice";

export enum SortBy {
  STRATEGY_NAME = 1,
  FARMING_APY = 2,
  TOTAL_STAKED = 3,
}

export const usePoolFilter = (pools: FarmingPool[], status: FarmingPoolStatus) => {
  const [showStaked, setShowStaked] = useState(false);
  const [sortBy, setSortBy] = useState(1);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(search);

  const [filteredPools, setFilteredPools] = useState<FarmingPool[]>([]);

  const handleSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedSearch(search);
    }, 300);
    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    const now = Date.now();
    const filtered = pools.filter(pool => {
      // corresponding filter rules are only evaluated if the predicate is defined
      const searchFilterPassed =
        !debouncedSearch || pool.strategyName.toLowerCase().includes(debouncedSearch.toLowerCase());
      const stakedFilterPassed = !showStaked || (pool.myStakedAmt && pool.myStakedAmt !== "0");
      const statusFilterPassed =
        status === "ACTIVE" ? pool.startTime <= now && now < pool.endTime : now >= pool.endTime;
      return statusFilterPassed && searchFilterPassed && stakedFilterPassed;
    });

    filtered.sort((pool1, pool2) => {
      switch (sortBy) {
        case SortBy.STRATEGY_NAME:
          return pool1.strategyName.localeCompare(pool2.strategyName);
        case SortBy.FARMING_APY:
          return pool2.apy - pool1.apy;
        case SortBy.TOTAL_STAKED: {
          const num1 = parseFloat(formatUnits(pool1.totalStaked, pool1.shareDecimal));
          const num2 = parseFloat(formatUnits(pool2.totalStaked, pool2.shareDecimal));
          return num2 - num1;
        }
        default:
          return 0;
      }
    });

    setFilteredPools(filtered);
  }, [pools, sortBy, debouncedSearch, showStaked, status]);

  const filters = {
    showStaked,
    setShowStaked,
    sortBy,
    setSortBy,
    search,
    setSearch: handleSearch,
  };

  return { filteredPools, filters };
};
