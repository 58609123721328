import { BigNumber } from "@ethersproject/bignumber";
import { AddressZero, MaxUint256 } from "@ethersproject/constants";
import { parseUnits } from "@ethersproject/units";
import { Avatar, Typography } from "antd";
import { ButtonType } from "antd/lib/button";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";
import { components } from "../../../api/api";
import { checkDepositLimit } from "../../../api/rollupChain";
import { formatDecimal, formatMula } from "../../../helpers/format";
import { useCustomContractLoader, useTokenBalance } from "../../../hooks";
import { useContractsContext } from "../../../providers/ContractsContextProvider";
import { useWeb3Context } from "../../../providers/Web3ContextProvider";
import { addDepositTx, IPersistedTx } from "../../../redux/persistedTxSlice";
import { useAppDispatch } from "../../../redux/store";
import { Theme } from "../../../theme/theme";
import { ERC20 } from "../../../typechain/ERC20";
import { ERC20__factory } from "../../../typechain/factories/ERC20__factory";
import { ITokenInputChangeEvent } from "../../TokenInput";
import ActionModal from "../common/ActionModal";
import ActionTitle from "../common/ActionTitle";
import ModalExtraInfoRow from "../common/ModalExtraInfoRow";
import ModalResult from "../common/ModalResult";
import ModalTokenInput from "../common/ModalTokenInput";

const useStyles = createUseStyles((theme: Theme) => ({
  balanceText: {
    textDecoration: "underline",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  explanation: {
    color: theme.fontColorSecondary,
    marginBottom: 24,
  },
}));

export interface DWModalProps {
  token: components["schemas"]["Asset"];
  minDeposit?: string; // formatted decimal
  onClose: () => void;
  onError?: (msg: string) => void;
}

export default function DepositModal({ token, onClose, minDeposit, onError = () => {} }: DWModalProps): JSX.Element {
  const classes = useStyles();
  const {
    contracts: { rollupChain },
    transactor,
  } = useContractsContext();
  const { provider, address } = useWeb3Context();
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState<string | undefined>("");
  const [resultMsg, setResultMsg] = useState("");
  const [allowance, setAllowance] = useState<BigNumber>();
  const tokenContract = useCustomContractLoader(provider, token.address || "", ERC20__factory) as ERC20 | undefined;
  const history = useHistory();

  const getAllowance = useCallback(() => {
    tokenContract?.allowance(address, rollupChain?.address || AddressZero).then(result => setAllowance(result));
  }, [address, rollupChain, tokenContract]);

  useEffect(() => {
    getAllowance();
  }, [getAllowance]);
  const [tokenBalance, balanceLoading] = useTokenBalance(tokenContract, address);
  const dispatch = useAppDispatch();

  const handleAction = async () => {
    if (!transactor || !allowance || !rollupChain || !tokenContract) {
      return;
    }

    const value = parseUnits(amount || "0", token.decimal);

    try {
      if (value.gt(allowance) || allowance.isZero()) {
        const approveTx = await transactor(tokenContract.approve(rollupChain.address, MaxUint256));
        setLoading(true);
        await approveTx.wait();
        setLoading(false);
        getAllowance();
      }
      if (value.isZero()) {
        return;
      }
      if (value.gt(tokenBalance)) {
        setErrMsg("Insufficient L1 balance in your wallet");
        return;
      }
      if (minDeposit && value.lt(parseUnits(minDeposit, token.decimal))) {
        setErrMsg(`Please input a number larger than ${minDeposit}`);
        return;
      }
      setLoading(true);
      const { isDepositLimitReached, limit, netDeposit } = await checkDepositLimit(rollupChain, token.address, value);
      if (isDepositLimitReached) {
        const msg = `Currently, the ${token.symbol} pool can only accept a maximum deposit of ${formatDecimal(
          limit.sub(netDeposit),
          token.decimal,
        )} ${token.symbol}. Please revise your deposit amount.`;
        onError(msg);
        onClose();
        return;
      }
      const depositTx = await transactor(rollupChain.deposit(token.address || "", value));
      const now = new Date().getTime();
      const tx: IPersistedTx = {
        ownerAddress: address,
        tnId: now,
        hash: depositTx.hash,
        assetAmt: value.toString(),
        assetSymbol: token.symbol,
        assetDecimal: token.decimal,
        // HACK: locally generate history entry doesn't have any canonical timestamp, using local machine time instead
        executeTimestamp: now,
        acceptTimestamp: now,
      };
      dispatch(addDepositTx(tx));
      setResultMsg("Deposit Has Been Submitted");
    } catch (e) {
      setErrMsg(_.get(e, "error.message"));
    } finally {
      setLoading(false);
    }
  };

  const handleGoToHistory = () => {
    history.push("/history");
  };

  const handleTokenInputChange = (e: ITokenInputChangeEvent) => {
    setAmount(e.value);
    setErrMsg(e.error);
  };

  let content;
  let actionText = "Deposit";
  let buttonType: ButtonType = "primary";
  if (resultMsg) {
    content = (
      <ModalResult
        title={resultMsg}
        description="Deposit will be credited to your L2 balance after 5 block confirmations (about 3-5 minutes). You can check the progress in the history page."
      />
    );
    actionText = "Check History";
    buttonType = "link";
  } else if (allowance && allowance.isZero()) {
    content = (
      <div className={classes.content}>
        <Avatar src={token.iconUrl} alt={token.name} style={{ marginBottom: 12 }} />
        <Typography.Title level={5}>{token.name}</Typography.Title>
        <Typography.Text className="sub-typography">Approve to continue deposit</Typography.Text>
      </div>
    );
    actionText = "Approve";
  } else {
    content = (
      <ModalTokenInput
        description="You first need to transfer assets from your L1 wallet to L2 to start
        investing and enjoy the gas-free DeFi experience."
        amount={amount}
        maxAmount={formatDecimal(tokenBalance.toString(), token.decimal)}
        maxLoading={balanceLoading}
        symbol={token.symbol}
        onChange={handleTokenInputChange}
        bottomDescription={minDeposit && `Minimal Deposit Amount: ${minDeposit} ${token.symbol}`}
      />
    );
    buttonType = "primary";
    actionText = "Deposit";
  }

  return (
    <ActionModal
      visible
      title={<ActionTitle title="Deposit to L2" token={token} />}
      actionText={actionText}
      actionLoading={loading}
      actionDisabled={!amount && !allowance?.isZero()}
      errMsg={errMsg}
      onCancel={onClose}
      onAction={resultMsg ? handleGoToHistory : handleAction}
      buttonType={buttonType}
      extra={
        resultMsg
          ? [<ModalExtraInfoRow left="Deposit Amount" right={`${formatMula(amount, "")} ${token.symbol}`} />]
          : []
      }
    >
      {content}
    </ActionModal>
  );
}
