import axios, { AxiosResponse } from "axios";
import { TrackJS } from "trackjs";
import { getRpcUrl } from "./env";

const rpcUrl = getRpcUrl();

export const get = async <T>(path: string): Promise<T> => {
  const res = await axios.get(`${rpcUrl}${path}`).then(getErrorHandler<T>(path));
  return res.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const post = async <T>(path: string, body: any): Promise<T> => {
  const res = await axios.post(`${rpcUrl}${path}`, body).then(getErrorHandler<T>(path, body));
  return res.data;
};

const getErrorHandler =
  <T>(path: string, body?: unknown) =>
  (response: AxiosResponse<T>) => {
    if (response.status >= 400) {
      TrackJS.track(`
      response to ${path} failed with status code ${response.status}
      request body: ${body}
      response: ${response.data}
      `);
    }
    return response;
  };
