import { CheckCircleFilled } from "@ant-design/icons";
import { FC, ReactNode } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Media } from "../../../theme/media";
import { Theme } from "../../../theme/theme";

interface IProps {
  title: ReactNode;
  description?: ReactNode;
}

const useStyles = createUseStyles<string, unknown, Theme>(theme => ({
  container: {},
  title: {
    marginBottom: 12,
    fontSize: theme.fontSizeL,
  },
  description: {
    color: theme.fontColorSecondary,
    lineHeight: 1.3,
  },
  icon: {
    marginTop: 36,
    marginBottom: 36,
  },
  [Media.S]: {
    icon: {
      marginBottom: 0,
    },
  },
}));

const ModalResult: FC<IProps> = props => {
  const { title, description } = props;
  const classes = useStyles();
  const theme = useTheme<Theme>();

  return (
    <div className={classes.container}>
      <h3 className={classes.title}>{title}</h3>
      {description && <div className={classes.description}>{description}</div>}
      <div className={classes.icon}>
        <CheckCircleFilled style={{ color: theme.successColor, fontSize: 32 }} />
      </div>
    </div>
  );
};

export default ModalResult;
