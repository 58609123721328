import React, { FC, ReactNode } from "react";
import { createUseStyles } from "react-jss";
import { components } from "../api/api";
import { Theme } from "../theme/theme";
import LabelWithPopover from "./LabelWithPopover";
import StrategyAPY from "./StrategyAPY";

interface IProps {
  strategy: components["schemas"]["Strategy"];
  label: ReactNode;
}

const useStyles = createUseStyles((theme: Theme) => ({
  warnIcon: {
    color: theme.warn,
  },
}));

const StrategyAPYTooltip: FC<IProps> = props => {
  const { strategy, label } = props;
  const classes = useStyles();

  return (
    <LabelWithPopover label={label} iconClassName={strategy.notRiskFree ? classes.warnIcon : ""}>
      <StrategyAPY strategy={strategy} />
    </LabelWithPopover>
  );
};

export default StrategyAPYTooltip;
