import { Button, Modal } from "antd";
import { ButtonType } from "antd/lib/button";
import { Children, FC, ReactNode } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Theme, ThemeType } from "../../../theme/theme";

const useStyles = createUseStyles((theme: Theme) => ({
  footerExtra: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "6px 0",
  },
  alert: {
    color: ["#FC5656", "!important"],
    textAlign: "center",
    display: "block",
    marginBottom: 6,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 16,
    padding: "0 16px",
  },
  list: {
    width: "100%",
    overflowY: "auto",
  },
  listEntry: {
    borderBottom: `1px solid ${theme.borderColorPrimary}`,
    "&:first-child": {
      borderTop: `1px solid ${theme.borderColorPrimary}`,
    },
  },
  buttonSection: {
    width: "100%",
  },
  buttonAtTop: {
    marginBottom: 12,
  },
  buttonAtBottom: {
    marginTop: 12,
  },
}));

interface IProps {
  title?: ReactNode;
  errMsg?: string;
  visible?: boolean;
  actionText?: string;
  actionLoading?: boolean;
  actionDisabled?: boolean | string;
  onAction?: () => void;
  onCancel?: () => void;
  footerExtra?: ReactNode;
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  buttonType?: ButtonType;
  extra?: ReactNode[];
}

const ActionModal: FC<IProps> = ({
  children,
  title,
  errMsg,
  visible,
  actionText,
  actionLoading,
  actionDisabled,
  onAction,
  onCancel,
  footerExtra = undefined,
  buttonType = "primary",
  extra = [],
}) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();

  const isMobile = theme.type === ThemeType.S;

  const animationProps =
    theme.type === ThemeType.S
      ? {
          transitionName: "",
          maskTransitionName: "",
        }
      : {};

  const buttonSection = (
    <>
      <div className={classes.footerExtra}>
        {footerExtra}
        {errMsg && <span className={classes.alert}>{errMsg}</span>}
      </div>
      <div className={classes.buttonSection}>
        <Button
          type={buttonType}
          size="large"
          block
          loading={actionLoading}
          onClick={onAction}
          className={isMobile ? classes.buttonAtTop : classes.buttonAtBottom}
          disabled={!!actionDisabled || !!errMsg}
        >
          {actionText || "OK"}
        </Button>
      </div>
    </>
  );

  return (
    <Modal
      closable
      title={title ?? <div />}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...animationProps}
    >
      {children && Children.count(children) > 0 && <div className={classes.content}>{children}</div>}
      {isMobile && buttonSection}
      {extra?.length ? (
        <div className={classes.list}>
          {extra.map((child, i) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i} className={classes.listEntry}>
                {child}
              </div>
            );
          })}
        </div>
      ) : null}
      {!isMobile && buttonSection}
    </Modal>
  );
};

export default ActionModal;
