import { Avatar, Button } from "antd";
import classNames from "classnames";
import React, { FC, ReactNode } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { components } from "../api/api";
import { FarmingPoolStatus } from "../api/farming";
import tokens from "../constants/tokens";
import { formatDate, formatDecimal, formatPercentage, formatUSD } from "../helpers/format";
import { eq } from "../helpers/numbers";
import { FarmingPool } from "../redux/farmingPoolSlice";
import { Theme, ThemeType } from "../theme/theme";
import Gain from "./Gain";
import LabelWithPopover from "./LabelWithPopover";
import TitleSubtitle from "./TitleSubtitle";

const useStyles = createUseStyles<string, unknown, Theme>(theme => {
  return {
    container: {
      border: theme.border,
      borderRadius: 16,
      padding: theme.type === ThemeType.S ? 12 : 16,
      minHeight: theme.type === ThemeType.S ? undefined : 532,
      display: "flex",
      flexDirection: "column",
      "& .ant-btn": {
        borderRadius: theme.borderRadiusS,
      },
      margin: 12,
    },
    section: {
      minHeight: 50,
      "&:not(:last-child)": {
        marginBottom: 16,
      },
    },
    top: {
      marginLeft: -4,
      marginTop: -4,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    pill: {
      height: 28,
      borderRadius: 14,
      minWidth: 66,
      padding: "0 4px",
      lineHeight: "28px",
      textAlign: "center",
    },
    infoCard: {
      borderRadius: theme.borderRadiusL,
      display: "flex",
      padding: theme.cardPaddingM,
      background: theme.bgColorTertiary,
      alignItems: "center",
    },
    infoCardCol: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: 1,
    },
    infoCardLabel: {
      fontWeight: theme.fontWeightBold,
      fontSize: theme.fontSizeL,
      color: theme.fontColorPrimary,
    },
    sep: {
      height: 24,
      borderRight: theme.border,
    },
    apyRow: {
      color: theme.inverseFontColorSecondary,
      display: "flex",
      alignItems: "center",
      "&:not(:last-child)": {
        marginBottom: 4,
      },
    },
    footer: {
      display: "flex",
      marginTop: "auto",
      "& button": {
        flex: 1,
        height: 56,
        padding: 0,
      },
      "& button:not(:last-child)": {
        marginRight: 8,
      },
    },
    infoRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 18,
    },
    infoRowLabel: {
      color: theme.fontColorSecondary,
    },
    rewardLabel: {
      display: "flex",
      alignItems: "center",
      flexWrap: "nowrap",
    },
    rewardLabelIcon: {
      marginRight: 4,
    },
  };
});

interface IProps {
  pool: FarmingPool;
  poolStatus: FarmingPoolStatus;
  onStake: (pool: FarmingPool) => void;
  onUnStake: (pool: FarmingPool) => void;
  onClaim: (pool: FarmingPool) => void;
  disabled?: boolean;
}

interface IInfoRowProps {
  label: ReactNode;
  tooltip?: string | number;
  value?: string;
}

const InfoRow: FC<IInfoRowProps> = props => {
  const { label, tooltip, value } = props;
  const classes = useStyles();
  return (
    <div className={classes.infoRow}>
      {tooltip ? (
        <LabelWithPopover label={label} labelClassName={classes.infoRowLabel}>
          {tooltip}
        </LabelWithPopover>
      ) : (
        <span className={classes.infoRowLabel}>{label}</span>
      )}
      <span className={classes.infoRowValue}>{value}</span>
    </div>
  );
};

const FarmingPoolCard: FC<IProps> = props => {
  const { pool, onStake, onUnStake, onClaim, poolStatus, disabled } = props;
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const isPoolActive = poolStatus === "ACTIVE";

  const pillStyle = isPoolActive
    ? {
        backgroundColor: theme.bgColorWin,
        color: theme.win,
      }
    : {
        backgroundColor: theme.bgColorSubtle,
        color: theme.fontColorSecondary,
      };

  const renderRewardLabel = (asset: components["schemas"]["RewardAsset"]) => {
    return (
      <div className={classes.rewardLabel}>
        <Avatar src={asset.assetIconUrl} size={16} className={classes.rewardLabelIcon} />
        {`${asset.assetSymbol} Reward`}
      </div>
    );
  };

  const farmingTooltip = `This is your current claimable farming rewards. Note that there might be some delay in updating your farming rewards, usually about ${process.env.REACT_APP_FARMING_UPDATE_INTERVAL}.`;

  return pool ? (
    <div className={classes.container}>
      <div className={classNames(classes.section, classes.top)}>
        <Avatar.Group>
          {pool.protocols?.map(p => (
            <Avatar key={p.name} src={p.iconUrl} size={40} />
          ))}
        </Avatar.Group>
        <div className={classes.pill} style={pillStyle}>
          {isPoolActive ? "Active" : "Ended"}
        </div>
      </div>
      <TitleSubtitle
        title={pool.strategyName}
        titleColor={theme.fontColorPrimary}
        subtitle={pool.description}
        className={classes.section}
      />
      <div className={classNames(classes.section, classes.infoCard)}>
        <div className={classes.infoCardCol}>
          <LabelWithPopover label="Farming APY" iconOnLeft labelClassName={classes.infoCardLabel}>
            {pool.farmingAssets?.map(a => (
              <div className={classes.apyRow} key={a.assetId}>
                <Avatar
                  size="small"
                  key={a.assetId}
                  src={tokens[a.assetSymbol].iconUrl}
                  style={{ border: theme.borderLighter, marginRight: 8 }}
                />
                {formatPercentage(a.apy)} - {a.assetSymbol} farming APY
              </div>
            ))}
          </LabelWithPopover>
          <Gain size={theme.fontSizeL} type={pool.apy}>
            {formatPercentage(pool.apy)}
          </Gain>
        </div>
        <div className={classes.sep} />
        <div className={classes.infoCardCol}>
          <span className={classes.infoCardLabel}>Total Staked</span>
          <Gain size={theme.fontSizeL} type="win">
            {formatUSD(pool.totalStakedUsd)}
          </Gain>
        </div>
      </div>
      <div className={classNames(classes.section, classes.list)}>
        <InfoRow label="Start Time" value={formatDate(pool.startTime)} />
        <InfoRow label="End Time" value={formatDate(pool.endTime)} />
        <InfoRow label="Your Stake" value={`${formatDecimal(pool.myStakedAmt, pool.shareDecimal)} ${pool.shareName}`} />
        {pool.farmingAssets?.map(asset => (
          <InfoRow
            key={asset.assetId}
            label={renderRewardLabel(asset)}
            value={`${formatDecimal(asset.amount, asset.assetDecimal)} ${asset.assetSymbol}`}
            tooltip={farmingTooltip}
          />
        ))}
      </div>
      <div className={classNames(classes.section, classes.footer)}>
        <Button type="primary" disabled={!isPoolActive || disabled} onClick={() => onStake(pool)}>
          Stake
        </Button>
        <Button type="primary" disabled={eq(pool.myStakedAmt, 0) || disabled} onClick={() => onUnStake(pool)}>
          Unstake
        </Button>
        <Button type="primary" disabled={!pool.claimable || disabled} onClick={() => onClaim(pool)}>
          Claim Rewards
        </Button>
      </div>
    </div>
  ) : null;
};

export default FarmingPoolCard;
