import { lte } from "lodash";
import React, { FC, useEffect, useMemo, useState } from "react";

import { farmingOperation } from "../../../api/farming";
import { TnType } from "../../../constants/tnType";
import { formatDecimal } from "../../../helpers/format";
import { gte, lt, sub } from "../../../helpers/numbers";
import { hashFarmingRequest } from "../../../helpers/requestHashers";
import { IValidator, useTokenInputState } from "../../../helpers/tokenAmountState";
import { useTransitionOperator } from "../../../hooks/operation/transitionOperator";
import { FarmingPool } from "../../../redux/farmingPoolSlice";
import LabelWithPopover from "../../LabelWithPopover";
import { ITokenInputChangeEvent } from "../../TokenInput";
import ActionModal from "../common/ActionModal";
import ActionTitle from "../common/ActionTitle";
import ModalExtraInfoRow from "../common/ModalExtraInfoRow";
import ModalResult from "../common/ModalResult";
import ModalTokenInput from "../common/ModalTokenInput";
import Info from "./Info";

interface IProps {
  pool: FarmingPool;
  visible: boolean;
  onClose: (shouldReload?: boolean) => void;
}

const UnstakeModal: FC<IProps> = props => {
  const { pool, visible, onClose } = props;

  const [actualUnstakeAmount, setActualUnstakeAmount] = useState("");

  const validators: IValidator[] = useMemo(
    () => [
      {
        validator: amtBig => lte(amtBig, pool.myStakedAmt),
        message: `Insufficient ${pool.shareName} balance to withdraw from the farming pool.`,
      },
      {
        validator: amtBig => gte(amtBig, pool.unstakeFee.amount),
        message: `Please enter an amount larger than fee`,
      },
      {
        validator: amtBig => gte(amtBig, pool.stakeFee.amount),
        message: "Please enter an amount larger than the fee.",
      },
    ],
    [pool],
  );
  const [amount, setAmount, resetInput] = useTokenInputState(pool.shareDecimal, undefined, validators);

  const { operation, signAndOperate, resetOperationState } = useTransitionOperator(
    farmingOperation,
    hashFarmingRequest,
  );

  useEffect(() => {
    const actualAmount = sub(amount.big, pool.unstakeFee.amount); // stake amount - stake fee = actual stake
    setActualUnstakeAmount(actualAmount.toString());
  }, [amount, pool.shareDecimal, pool.unstakeFee.amount]);

  const handleUnstake = async () => {
    signAndOperate({
      type: TnType.Unstake,
      poolId: pool.id,
      fee: pool.unstakeFee.amount || "0",
      shares: amount.big.toString(),
      timestamp: Date.now(),
      signature: "",
    });
  };

  const handleChange = (e: ITokenInputChangeEvent) => {
    setAmount(e.value);
  };

  const handleModalClose = () => {
    resetOperationState();
    resetInput();
    onClose(operation.completed);
  };

  const assetNames = pool.farmingAssets.map(asset => asset.assetSymbol).join(" and ");

  return (
    <ActionModal
      visible={visible}
      title={<ActionTitle title="Unstake and Claim" />}
      actionText={operation.completed ? "OK" : "Unstake and Claim"}
      errMsg={amount.error}
      actionLoading={operation.loading}
      onAction={operation.completed ? handleModalClose : handleUnstake}
      actionDisabled={!amount.input}
      onCancel={handleModalClose}
      extra={[
        <ModalExtraInfoRow key={1} left="Strategy Name" right={pool.strategyName} />,
        <Info.MiningRewardsInfo pool={pool} />,
        <Info.FeeInfo pool={pool} />,
        <ModalExtraInfoRow
          key={4}
          left={
            <LabelWithPopover label="Actual Unstake Amount">
              This is your actual unstake amount after the fee is deducted.
            </LabelWithPopover>
          }
          right={`${lt(actualUnstakeAmount, 0) ? 0 : formatDecimal(actualUnstakeAmount, pool.shareDecimal)} ${
            pool.shareName
          }`}
        />,
      ]}
    >
      {operation.completed ? (
        <ModalResult
          title="Unstake and Claim Success"
          description={`The farming rewards will show up in your L2 Balance of ${assetNames}`}
        />
      ) : (
        <ModalTokenInput
          description={`Unstake and claim ${assetNames} rewards.`}
          amount={amount.input}
          maxAmount={formatDecimal(pool.myStakedAmt, pool.shareDecimal)}
          symbol={pool.shareName}
          onChange={handleChange}
        />
      )}
    </ActionModal>
  );
};

export default UnstakeModal;
