import "antd/dist/antd.css";
import { ThemeProvider } from "react-jss";
import VConsole from "vconsole";
import { useWindowWidth } from "../hooks";
import { useAppSelector } from "../redux/store";
import mobileTheme from "../theme/mobile";
import defaultTheme from "../theme/theme";
import Home from "../views/Home";
import "./App.less";

if (process.env.REACT_APP_DEBUG) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const vConsole = new VConsole();
}

function App(): JSX.Element {
  const { isMobile } = useAppSelector(state => state.windowWidth);
  useWindowWidth();
  return (
    <ThemeProvider theme={!isMobile ? defaultTheme : mobileTheme}>
      <Home />
    </ThemeProvider>
  );
}

export default App;
