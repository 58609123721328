import { hexlify } from "ethers/lib/utils";
import { useCallback } from "react";
import { useWeb3Context } from "../../providers/Web3ContextProvider";

export const useOperationSigner = <T>(generateHash: (req: T) => string) => {
  const { address, signer } = useWeb3Context();

  const signReq = useCallback(
    async (req: T) => {
      const transitionHash = generateHash(req);

      if (!signer) {
        throw new Error("Could not sign request: Signer is empty");
      }
      const signature: string = await signer.provider.send("personal_sign", [
        hexlify(transitionHash),
        address.toLowerCase(),
      ]);
      const reqWithSig = { ...req, signature };
      return reqWithSig as T;
    },
    [address, generateHash, signer],
  );

  return signReq;
};
