import React, { FC } from "react";
import FarmingPools from "../components/FarmingPools";
import RouteTabbedCard, { IRoute } from "../components/RouteTabbedCard";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import { fetchPools } from "../redux/farmingPoolSlice";
import { useAppDispatch } from "../redux/store";

const Farming: FC = () => {
  const dispatch = useAppDispatch();
  const { address } = useWeb3Context();

  const refreshPools = () => {
    dispatch(fetchPools(address));
  };

  const routes: IRoute[] = [
    {
      path: "/farming/active",
      title: "Active",
      component: <FarmingPools status="ACTIVE" />,
      onReload: refreshPools,
    },
    {
      path: "/farming/ended",
      title: "Ended",
      component: <FarmingPools status="ENDED" />,
      onReload: refreshPools,
    },
  ];
  return <RouteTabbedCard routes={routes} redirect={{ from: "/farming", to: "/farming/active" }} />;
};

export default Farming;
