import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import classNames from "classnames";
import { FC, ReactNode } from "react";
import { createUseStyles } from "react-jss";
import { Strata } from "../constants/zIndex";
import { Theme } from "../theme/theme";

interface IProps {
  label?: string | ReactNode;
  subtitle?: string;
  padding?: number; // amount of paading in popover
  placement?: TooltipPlacement;
  className?: string;
  iconOnLeft?: boolean;
  centerLabel?: boolean;
  labelClassName?: string;
  iconClassName?: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
  overlay: {
    zIndex: Strata.TOOLTIP,
    "@global": {
      ".ant-popover-inner": {
        borderRadius: 8,
      },
      ".ant-popover-arrow": {
        background: "white",
        border: "none",
      },
    },
  },
  label: {
    display: "flex",
    alignItems: "center",
  },
  subtitle: {
    color: theme.fontColorSecondary,
  },
  popoverContent: {
    margin: 0,
    maxWidth: 300,
    color: theme.inverseFontColorPrimary,
  },
  iconWrapper: {
    position: "relative",
    display: "inline-block",
  },
  labelInfoIcon: {
    color: theme.fontColorSecondary,
    "&:hover": {
      color: theme.fontColorPrimary,
    },
  },
  iconOnLeft: {
    marginRight: 6,
  },
  iconOnRight: {
    marginLeft: 6,
  },
  offCenterIcon: {
    position: "absolute",
    top: -12,
  },
}));

const LabelWithPopover: FC<IProps> = props => {
  const {
    label,
    children,
    subtitle,
    padding,
    placement = "top",
    className,
    iconOnLeft = false,
    centerLabel = false,
    labelClassName,
    iconClassName,
  } = props;
  const classes = useStyles();

  const labelSpan = <span className={labelClassName}>{label}</span>;
  const labelPositionClass = iconOnLeft ? classes.iconOnLeft : classes.iconOnRight;

  return (
    <div className={classes.label}>
      {!iconOnLeft && labelSpan}
      <Popover
        color="white"
        placement={placement}
        overlayClassName={classNames(classes.overlay, className)}
        content={
          <div className={classes.popoverContent} style={{ padding: padding ?? "" }}>
            {children}
          </div>
        }
      >
        <div className={classes.iconWrapper}>
          <InfoCircleOutlined
            className={classNames(
              classes.labelInfoIcon,
              labelPositionClass,
              centerLabel && classes.offCenterIcon,
              iconClassName,
            )}
          />
        </div>
      </Popover>
      {iconOnLeft && labelSpan}
      {subtitle ? <div className={classes.subtitle}>{subtitle}</div> : null}
    </div>
  );
};

export default LabelWithPopover;
