export interface NetworkInfo {
  name: string;
  color: string;
  chainId: number;
  rpcUrl: string;
  blockTime: number;
}

export const INFURA_ID = process.env.REACT_APP_INFURA_ID;
export const RPC_URL = process.env.REACT_APP_RPC_PROVIDER_URL;

export const NETWORKS = {
  localhost: {
    name: "localhost",
    color: "#666666",
    chainId: 31337,
    rpcUrl: process.env.REACT_APP_SERVER_URL || "http://localhost:8081",
    blockTime: 3000,
  },
  mainnet: {
    name: "mainnet",
    color: "#29b6af",
    chainId: 1,
    rpcUrl: process.env.REACT_APP_SERVER_URL || "https://api-mainnet.layer2.finance",
    blockTime: 15000,
  },
  ropsten: {
    name: "ropsten",
    color: "#ff4a8d",
    chainId: 3,
    rpcUrl: process.env.REACT_APP_SERVER_URL || "https://api-ropsten-test.layer2.finance",
    blockTime: 15000,
  },
  rinkeby: {
    name: "rinkeby",
    color: "#f6c343",
    chainId: 4,
    rpcUrl: process.env.REACT_APP_SERVER_URL || "https://api-rinkeby.layer2.finance",
    blockTime: 15000,
  },
  goerli: {
    name: "goerli",
    color: "#3099f2",
    chainId: 5,
    rpcUrl: process.env.REACT_APP_SERVER_URL || "https://api-goerli-test.layer2.finance",
    blockTime: 15000,
  },
  kovan: {
    name: "kovan",
    color: "#7057ff",
    chainId: 42,
    rpcUrl: process.env.REACT_APP_SERVER_URL || "https://api-kovan.layer2.finance",
    blockTime: 4000,
  },
  test: {
    name: "unknown",
    color: "#7057ff",
    chainId: 883,
    rpcUrl: process.env.REACT_APP_SERVER_URL || "https://api-goerli-test.layer2.finance",
    blockTime: 4000,
  },
};

export const getNetworkById: (chainId: number) => NetworkInfo = (chainId: number) => {
  /* eslint-disable-next-line no-restricted-syntax */
  for (const network of Object.values(NETWORKS)) {
    if (network.chainId === chainId) {
      return network;
    }
  }
  return NETWORKS.localhost;
};

export const USD_TOKENS = {
  BUSD: true,
  USDC: true,
  USDT: true,
  DAI: true,
};
