import { ButtonType } from "antd/lib/button";
import { ReactNode, useMemo } from "react";
import { useHistory } from "react-router";

import { components } from "../../../api/api";
import { strategyOperation } from "../../../api/strategy";
import { formatDecimal, formatPercentage } from "../../../helpers/format";
import { gt, gte, lte } from "../../../helpers/numbers";
import { hashStrategyRequest } from "../../../helpers/requestHashers";
import { getRedeemedAmountAfterBuy, getTotalAPY } from "../../../helpers/strategy";
import { IValidator, useTokenInputState } from "../../../helpers/tokenAmountState";
import { useTransitionOperator } from "../../../hooks/operation/transitionOperator";
import { useWeb3Context } from "../../../providers/Web3ContextProvider";
import { fetchAssets } from "../../../redux/assetSlice";
import { useAppDispatch } from "../../../redux/store";
import { fetchStrategies } from "../../../redux/strategySlice";
import Gain from "../../Gain";
import LabelWithPopover from "../../LabelWithPopover";
import StrategyAPYTooltip from "../../StrategyAPYTooltip";
import { ITokenInputChangeEvent } from "../../TokenInput";
import ActionModal from "../common/ActionModal";
import ActionTitle from "../common/ActionTitle";
import ModalExtraInfoRow from "../common/ModalExtraInfoRow";
import ModalResult from "../common/ModalResult";
import ModalTokenInput from "../common/ModalTokenInput";

export interface FundExitModalProps {
  token: components["schemas"]["Asset"];
  strategy: components["schemas"]["Strategy"];
  onClose: () => void;
}

export default function FundModal({ token, strategy, onClose }: FundExitModalProps): JSX.Element {
  const { address } = useWeb3Context();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const minFund = useMemo(
    () => formatDecimal(strategy.minFund, token.decimal, token.decimal),
    [strategy.minFund, token.decimal],
  );
  const validators: IValidator[] = useMemo(
    () => [
      {
        validator: amtBig => gte(amtBig, strategy.minFund),
        message: `Please input a number larger than ${minFund}`,
      },
      {
        validator: amtBig => gt(amtBig, strategy.maxDepositFee?.amount),
        message: `Please enter an amount larger than the fee`,
      },
      {
        validator: amtBig => lte(amtBig, token.amount),
        message: `Insufficient ${token.symbol} balance in L2`,
      },
    ],
    [minFund, strategy, token],
  );

  const [amount, setAmount, resetInput] = useTokenInputState(token.decimal, undefined, validators);

  const { signAndOperate, operation, resetOperationState } = useTransitionOperator(
    strategyOperation,
    hashStrategyRequest,
  );

  const handleAction = async () => {
    signAndOperate({
      type: 4,
      strategyId: strategy.id as number,
      amount: amount.big.toString(), // asset amount
      timestamp: Date.now(),
      priceLimit: strategy.maxSharePriceForBuy as string,
      fee: strategy.maxDepositFee?.amount as string,
      signature: "",
    });
  };

  const handleModalClose = () => {
    if (operation.completed) {
      dispatch(fetchStrategies({ address, tokenId: token.id }));
      dispatch(fetchAssets(address));
      resetOperationState();
      resetInput();
    }
    onClose();
  };

  const handleTokenInputChange = (e: ITokenInputChangeEvent) => {
    setAmount(e.value);
  };

  const strategyNameRow = <ModalExtraInfoRow key={1} left="Strategy Name" right={strategy.name} />;
  const depositAmountRow = (
    <ModalExtraInfoRow key={2} left="Deposit Amount" right={`${amount.formatted} ${token.symbol}`} />
  );
  const totalAPY = getTotalAPY(strategy);
  const apyRow = (
    <ModalExtraInfoRow
      key={3}
      left={<StrategyAPYTooltip strategy={strategy} label="APY" />}
      right={<Gain type={totalAPY}>{formatPercentage(totalAPY, false)}</Gain>}
    />
  );
  const maxFeeRow = (
    <ModalExtraInfoRow
      key={4}
      left={
        <LabelWithPopover label="Max Fee">
          This the max processing fee charged for this transaction to cover the operation cost. Note that the actual fee
          may be smaller when this transaction is executed.
        </LabelWithPopover>
      }
      right={`${formatDecimal(strategy.maxDepositFee?.amount, strategy.maxDepositFee?.decimal)} ${
        strategy.maxDepositFee?.symbol
      }`}
    />
  );
  const yourReceiveRow = (
    <ModalExtraInfoRow
      key={5}
      left={
        <LabelWithPopover label="You Receive">
          Strategy Token (stToken) is your liquidity token that can be used to redeem the underlying liquidity along
          with yields back to your available L2 balance. This is the approximate strategy token amount you will receive
          after the max fee is deducted, estimated based on the current stToken price. The actual stToken amount you
          will receive may vary depending on the stToken price and the actual processing fee when this transaction is
          executed (up to {process.env.REACT_APP_CONFIRM_WINDOW} later).
        </LabelWithPopover>
      }
      right={`≈ ${formatDecimal(getRedeemedAmountAfterBuy(amount.big, strategy), token.decimal)} ${strategy.shareName}`}
    />
  );
  const slippageToleranceRow = (
    <ModalExtraInfoRow
      key={6}
      left={
        <LabelWithPopover label="Slippage Tolerance">
          Due to the fluctuation of strategy token price, the actual amount of {strategy.shareName} you will receive
          might be smaller than the estimated amount you see above. If the relative difference is larger than{" "}
          {formatPercentage(strategy.depositSlippageTolerance)}, the transaction will be reverted.
        </LabelWithPopover>
      }
      right={formatPercentage(strategy.depositSlippageTolerance)}
    />
  );

  let content: JSX.Element;
  let action: () => void;
  let actionText: string;
  let buttonType: ButtonType;
  let extraInfo: ReactNode[];
  if (operation.completed) {
    content = (
      <ModalResult
        title="Deposit has been submitted"
        description={
          <span>
            It may take up to {process.env.REACT_APP_CONFIRM_WINDOW} to finalize your deposit to the strategy. You can
            check the progress in the history page.
          </span>
        }
      />
    );
    action = () => history.push("/history/investment");
    actionText = "Check History";
    buttonType = "link";
    extraInfo = [strategyNameRow, depositAmountRow, apyRow, maxFeeRow, yourReceiveRow, slippageToleranceRow];
  } else {
    content = (
      <ModalTokenInput
        description="You can allocate your L2 balance to this strategy and receive earnings."
        amount={amount.input}
        maxAmount={formatDecimal(token.amount, token.decimal)}
        symbol={token.symbol}
        onChange={handleTokenInputChange}
        bottomDescription={`Minimal Deposit Amount: ${minFund} ${token.symbol}`}
      />
    );
    action = handleAction;
    actionText = "Deposit";
    buttonType = "primary";
    extraInfo = [apyRow, strategyNameRow, maxFeeRow, yourReceiveRow, slippageToleranceRow];
  }

  return (
    <ActionModal
      visible
      title={<ActionTitle title="Deposit to Strategy" token={token} />}
      actionText={actionText}
      actionDisabled={!amount.input}
      errMsg={amount.error}
      onCancel={handleModalClose}
      onAction={action}
      actionLoading={operation.loading}
      buttonType={buttonType}
      extra={extraInfo}
    >
      {content}
    </ActionModal>
  );
}
