import { BigNumber } from "ethers";
import { components } from "../api/api";

export const getTotalAPY = (strategy: components["schemas"]["Strategy"]) => {
  return (strategy.apy || 0) + getFarmingAPY(strategy.farmingAssets);
};

export const getFarmingAPY = (farmingAssets: components["schemas"]["RewardAsset"][] = []) => {
  return farmingAssets?.reduce((total, a) => total + a.apy, 0.0) || 0;
};

export const getRedeemedAmountAfterSell = (sellAmount: BigNumber, strategy: components["schemas"]["Strategy"]) => {
  if (!sellAmount || sellAmount.isZero()) {
    return BigNumber.from(0);
  }
  const { maxWithdrawFee, withdrawSlippageTolerance, minSharePriceForSell } = strategy;
  return sellAmount
    .sub(maxWithdrawFee?.amount || 0)
    .mul((withdrawSlippageTolerance || 0) * 100 + 100)
    .div(100)
    .mul(minSharePriceForSell || 0)
    .div(BigNumber.from(10).pow(18));
};

export const getRedeemedAmountAfterBuy = (buyAmount: BigNumber, strategy: components["schemas"]["Strategy"]) => {
  if (!buyAmount || buyAmount.isZero()) {
    return BigNumber.from(0);
  }
  const { maxDepositFee, depositSlippageTolerance, maxSharePriceForBuy } = strategy;
  return buyAmount
    .sub(maxDepositFee?.amount || 0)
    .mul((depositSlippageTolerance || 0) * 100 + 100)
    .div(100)
    .mul(BigNumber.from(10).pow(18)) // since maxSharePriceForBuy is o 1e18 scale, need to multiply this to compensate the lost digits
    .div(maxSharePriceForBuy || 0);
};
