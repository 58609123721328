import { message } from "antd";
import { useCallback, useState } from "react";

import { useOperationSigner } from "./operationSigner";

export const useTransitionOperator = <T, R>(operator: (params: T) => Promise<R>, requestHasher: (req: T) => string) => {
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const operationSigner = useOperationSigner(requestHasher);

  const operation = {
    loading,
    completed,
  };

  const signAndOperate = useCallback(
    async (params: T) => {
      setLoading(true);
      try {
        const req = await operationSigner(params);
        const res = await operator(req);
        setLoading(false);
        setCompleted(true);
        return res;
      } catch (e) {
        setLoading(false);
        console.error(e);
        if (e instanceof Error) {
          message.error(e.message);
        }
      }
      return undefined;
    },
    [operationSigner, operator],
  );

  const resetOperationState = useCallback(() => {
    setLoading(false);
    setCompleted(false);
  }, []);

  return { operation, signAndOperate, resetOperationState };
};
