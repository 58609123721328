import React, { FC } from "react";
import { formatDecimal } from "../../../helpers/format";
import { FarmingPool } from "../../../redux/farmingPoolSlice";
import LabelWithPopover from "../../LabelWithPopover";
import MiningRewards from "../../MiningRewards";
import ModalExtraInfoRow from "../common/ModalExtraInfoRow";

interface IProps {
  pool: FarmingPool;
  showTooltip?: boolean;
}

const MiningRewardsInfo: FC<IProps> = ({ pool, showTooltip = true }) => (
  <ModalExtraInfoRow
    key={2}
    left={
      showTooltip ? (
        <LabelWithPopover label="Mining Reward(s)">
          Your farming rewards will be automatically harvested when you unstake from the farming program.
        </LabelWithPopover>
      ) : (
        "Mining Reward(s)"
      )
    }
    right={<MiningRewards rewards={pool.farmingAssets} />}
  />
);

const FeeInfo: FC<IProps> = ({ pool, showTooltip = true }) => (
  <ModalExtraInfoRow
    key={3}
    left={
      showTooltip ? (
        <LabelWithPopover label="Fee">
          This is the fee charged to cover the operation cost of this transaction.
        </LabelWithPopover>
      ) : (
        "Fee"
      )
    }
    right={`${formatDecimal(pool.unstakeFee.amount, pool.unstakeFee.decimal)} ${pool.unstakeFee.symbol}`}
  />
);

const Info = { FeeInfo, MiningRewardsInfo };

export default Info;
