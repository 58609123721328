import { CloseOutlined } from "@ant-design/icons";
import { Avatar, Card, List } from "antd";
import { FC } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { components } from "../api/api";
import tokens from "../constants/tokens";
import { Strata } from "../constants/zIndex";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { saveShow } from "../redux/tokenToggleSlice";
import { ScreenWidth } from "../theme";
import { Theme, ThemeType } from "../theme/theme";

const useStyles = createUseStyles((theme: Theme) => ({
  card: {
    background: theme.type === ThemeType.S ? theme.modalbackground : undefined,
    "@global": {
      ".ant-list-item": {
        padding: "8px 12px",
        margin: "16px 0",
      },
      ".ant-list-item-meta-title": {
        fontSize: 16,
        marginBottom: 0,
      },
      ".ant-list-item-meta-description": {
        fontSize: 12,
      },
    },
    "&.ant-card": {
      height: "100%",
    },
    "& .ant-card-body": {
      padding: 0,
      overflow: "hidden",
    },

    "& .ant-list-item": {
      border: "none",
    },

    "& .ant-list-item-meta": {
      alignItems: "center",
    },
    "& .ant-card-head-title": {
      padding: "24px 0",
    },
  },
  listItem: {
    "& .ant-list-item-meta-title": {
      lineHeight: 1,
    },
  },
  item: {
    cursor: "pointer",
    overflow: "hidden",
  },
  activeItem: {
    background: theme.bgColorHighlightOnPrimary,
    transition: "ease 0.3s",
    borderRadius: theme.borderRadiusS,
    pointerEvents: "none",

    "& $tokenButton $tokenName": {
      color: theme.fontColorPrimary,
    },
  },
  cardmodal: {
    position: "fixed",
    width: "100%",
    height: "100%",
    bottom: 0,
    left: 0,
  },
  modalcover: {
    height: "5%",
    width: "100%",
    background: "rgba(0,0,0, 0.4)",
  },
  modalcontent: {
    width: "100%",
    height: "95%",
    borderRadius: "4px 0",
    position: "relative",
  },
  closeIcon: {
    fontSize: 20,
    color: "#fff",
    position: "absolute",
    top: 24,
    right: 20,
    zIndex: Strata.FULLSCREEN,
  },
  tokenButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  tokenName: {
    marginTop: 8,
    fontSize: theme.fontSizeS,
    color: theme.fontColorSecondary,
    lineHeight: 1,
  },
}));

interface IProps {
  onSelectToken: (tokenId: number) => void;
}

const TokenList: FC<IProps> = ({ onSelectToken }) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const isMobile = theme.type === ThemeType.S;
  const { screenWidth } = useAppSelector(state => state.windowWidth);
  const { assets, selectedIndex } = useAppSelector(state => state.asset);
  const selectedToken = assets[selectedIndex];
  const dispatch = useAppDispatch();

  const renderTokenItem = (token: components["schemas"]["Asset"]) => {
    if (!token) {
      return null;
    }
    let tokenName = token.name;
    let tokenSymbol = token.symbol;
    // Special case WETH
    if (token.symbol === "WETH") {
      tokenName = "ETH";
      tokenSymbol = "ETH";
    }
    return (
      <div className={classes.listItem}>
        <List.Item
          className={selectedToken?.id === token.id ? classes.activeItem : classes.item}
          onClick={() => onSelectToken(token.id)}
        >
          {ScreenWidth.M < screenWidth && screenWidth <= ScreenWidth.L ? (
            <div className={classes.tokenButton}>
              <Avatar size="default" src={token.symbol && tokens[token.symbol]?.iconUrl} />
              <span className={classes.tokenName}>{tokenSymbol}</span>
            </div>
          ) : (
            <List.Item.Meta
              avatar={<Avatar size="default" src={token.symbol && tokens[token.symbol]?.iconUrl} />}
              title={tokenName}
              description={tokenSymbol}
            />
          )}
        </List.Item>
      </div>
    );
  };
  let sidebarWidth: number | undefined = 233;
  if (screenWidth <= ScreenWidth.S) {
    sidebarWidth = undefined;
  } else if (screenWidth <= ScreenWidth.L) {
    sidebarWidth = undefined;
  }

  return (
    <div className={isMobile ? classes.cardmodal : undefined}>
      <div className={classes.modalcover} />
      <div className={classes.modalcontent}>
        {isMobile && (
          <CloseOutlined className={classes.closeIcon} onClick={() => dispatch(saveShow({ IsShow: false }))} />
        )}
        <Card
          className={classes.card}
          title={ScreenWidth.M < screenWidth && screenWidth <= ScreenWidth.L ? "" : "Supported Tokens"}
          bodyStyle={{ padding: "0 12px", width: sidebarWidth }}
          headStyle={{ paddingLeft: 12, paddingRight: 12 }}
        >
          <List itemLayout="horizontal" size="small" dataSource={assets} renderItem={renderTokenItem} />
        </Card>
      </div>
    </div>
  );
};

export default TokenList;
