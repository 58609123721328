import { Avatar } from "antd";
import React, { FC } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { components } from "../api/api";
import tokens from "../constants/tokens";
import { formatPercentage } from "../helpers/format";
import { Theme } from "../theme/theme";

interface IProps {
  strategy: components["schemas"]["Strategy"];
}

const useStyles = createUseStyles((theme: Theme) => ({
  apyRow: {
    display: "flex",
    alignItems: "center",
    color: theme.inverseFontColorSecondary,
    fontSize: 12,
    "&:not(:last-child)": {
      marginBottom: 4,
    },
  },
  apyDescription: {
    color: theme.inverseFontColorSecondary,
    fontSize: 12,
    marginLeft: 8,
  },
}));
interface IFarmingAPYProps {
  farmingAssets?: components["schemas"]["RewardAsset"][];
}

export const FarmingAPY: FC<IFarmingAPYProps> = props => {
  const { farmingAssets = [] } = props;
  const theme = useTheme<Theme>();
  const classes = useStyles();

  return (
    <div>
      {farmingAssets?.map(a => (
        <div className={classes.apyRow} key={a.assetId}>
          <Avatar
            size="small"
            key={a.assetId}
            src={tokens[a.assetSymbol].iconUrl}
            style={{ border: theme.borderLighter, marginRight: 8 }}
          />
          {formatPercentage(a.apy)} - {a.assetSymbol} farming APY
        </div>
      ))}
    </div>
  );
};

const StrategyAPY: FC<IProps> = props => {
  const { strategy } = props;
  const theme = useTheme<Theme>();
  const classes = useStyles();

  const apyPercentage = formatPercentage(strategy.apy, false);

  return (
    <div>
      <div className={classes.apyRow}>
        <Avatar.Group style={{ marginRight: 8 }}>
          {strategy.protocols?.map(p => (
            <Avatar size="small" key={p.name} src={p.iconUrl} style={{ border: theme.borderLighter }} />
          ))}
        </Avatar.Group>
        {apyPercentage} - Strategy APY
      </div>
      {strategy.notRiskFree && (
        <p className={classes.apyDescription}>
          Note that this strategy is not risk-free. It may fluctuate and yield <b>negative strategy APY</b>.
        </p>
      )}
      <FarmingAPY farmingAssets={strategy.farmingAssets} />
    </div>
  );
};

export default StrategyAPY;
