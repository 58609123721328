import { Typography } from "antd";
import { FC, ReactNode } from "react";
import { createUseStyles } from "react-jss";
import { MaxButton, TokenInput } from "../..";
import { Media } from "../../../theme";
import { Theme } from "../../../theme/theme";
import { ITokenInputChangeEvent } from "../../TokenInput";

interface IProps {
  focusTrigger?: boolean; // any time this value changes the input is re-focused
  description: ReactNode;
  bottomDescription?: ReactNode;
  maxAmount?: string; // decimal formatted string
  maxLoading?: boolean;
  maxButton?: ReactNode;
  symbol?: string;
  amount: string;
  onChange: (e: ITokenInputChangeEvent) => void;
}

const useStyles = createUseStyles<string, unknown, Theme>(theme => ({
  bottomDesc: {
    marginTop: theme.modalBottom,
    fontSize: theme.fontSizeS,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  section: {
    marginBottom: theme.modalBottom,
  },
  description: {
    lineHeight: 1.4,
    fontSize: theme.fontSizeM,
    color: theme.fontColorSecondary,
  },
  [Media.XS]: {
    description: {
      lineHeight: 1.2,
    },
  },
}));

const ModalTokenInput: FC<IProps> = props => {
  const { amount, onChange, description, symbol, bottomDescription, maxAmount, maxLoading, maxButton } = props;
  const classes = useStyles();

  const handleSetMax = () => {
    let max = maxAmount;
    if (!max) {
      onChange({ value: "0" });
      return;
    }
    if (max.includes(",")) {
      max = max.split(",").join("");
    }
    onChange({ value: max });
  };

  return (
    <div className={classes.body}>
      <div className={classes.section}>
        <p className={classes.description}>{description}</p>
      </div>
      <TokenInput value={amount} onChange={onChange} />
      <Typography.Text>{symbol}</Typography.Text>
      {maxButton || <MaxButton loading={maxLoading} onClick={handleSetMax} value={maxAmount || "0"} symbol={symbol} />}
      {bottomDescription ? (
        <Typography.Text type="secondary" className={classes.bottomDesc}>
          {bottomDescription}
        </Typography.Text>
      ) : null}
    </div>
  );
};

export default ModalTokenInput;
