import { Button } from "antd";
import { Footer } from "antd/lib/layout/layout";
import React, { FC } from "react";
import { createUseStyles } from "react-jss";
import { Media } from "../theme";
import { Theme } from "../theme/theme";

const useStyles = createUseStyles((theme: Theme) => ({
  footer: {
    margin: "40px 0 20px",
    padding: "0 16px",
    display: "flex",
    alignItems: "center",
    "& p, button": {
      color: theme.fontColorTertiary,
      margin: 0,
      fontSize: theme.fontSizeS,
    },
  },
  footerLink: {
    marginRight: -8,
    "& span": {
      textDecoration: "underline",
    },
  },
  footerExtra: {
    marginLeft: "auto",
    color: theme.fontColorTertiary,
  },
  [Media.S]: {
    footer: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    footerExtra: {
      marginLeft: 0,
      marginTop: 10,
    },
  },
}));

const PageFooter: FC = () => {
  const classes = useStyles();
  return (
    <Footer className={classes.footer}>
      <div>
        <p>Built with ❤️ by the Celer community.</p>
        <p>
          © Copyright 2021 Faster Thunder Ltd.{" "}
          <Button
            size="small"
            type="link"
            className={classes.footerLink}
            onClick={() => window.open("./terms-of-use.pdf")}
          >
            Terms
          </Button>
          <Button
            size="small"
            type="link"
            className={classes.footerLink}
            onClick={() => window.open("./privacy-policy.pdf")}
          >
            Privacy
          </Button>
        </p>
      </div>
      <div className={classes.footerExtra}>
        <p>Powered by Celer Network</p>
      </div>
    </Footer>
  );
};

export default PageFooter;
