import React, { FC } from "react";

import { components } from "../../../api/api";
import { farmingOperation } from "../../../api/farming";
import { TnType } from "../../../constants/tnType";
import { hashFarmingRequest } from "../../../helpers/requestHashers";
import { useTransitionOperator } from "../../../hooks/operation/transitionOperator";
import { FarmingPool } from "../../../redux/farmingPoolSlice";
import ActionModal from "../common/ActionModal";
import ActionTitle from "../common/ActionTitle";
import ModalExtraInfoRow from "../common/ModalExtraInfoRow";
import ModalResult from "../common/ModalResult";
import Info from "./Info";

interface IProps {
  pool: FarmingPool;
  visible: boolean;
  onClose: (shouldReload?: boolean) => void;
}

const ClaimModal: FC<IProps> = props => {
  const { pool, visible, onClose } = props;

  const { operation, signAndOperate, resetOperationState } = useTransitionOperator(
    farmingOperation,
    hashFarmingRequest,
  );

  const handleUnstake = async () => {
    const req: components["schemas"]["FarmingOperationParams"] = {
      type: TnType.Unstake,
      poolId: pool.id,
      fee: pool.unstakeFee.amount || "0",
      shares: pool.unstakeFee.amount || "0",
      timestamp: Date.now(),
      signature: "",
    };
    signAndOperate(req);
  };

  const handleModalClose = () => {
    resetOperationState();
    onClose(operation.completed);
  };

  const assetNames = pool.farmingAssets.map(asset => asset.assetSymbol).join(" and ");
  return (
    <ActionModal
      visible={visible}
      title={<ActionTitle title="Claim Rewards" />}
      actionText={operation.completed ? "OK" : "Claim"}
      onAction={operation.completed ? handleModalClose : handleUnstake}
      actionLoading={operation.loading}
      onCancel={handleModalClose}
      extra={[
        <ModalExtraInfoRow key={1} left="Strategy Name" right={pool.strategyName} />,
        <Info.MiningRewardsInfo pool={pool} showTooltip={false} />,
        <Info.FeeInfo pool={pool} />,
      ]}
    >
      {operation.completed && (
        <ModalResult
          title="Claim Rewards Success"
          description={`The farming rewards will show up in your L2 Balance of ${assetNames}`}
        />
      )}
    </ActionModal>
  );
};

export default ClaimModal;
