import { JsonRpcSigner, Provider, Web3Provider } from "@ethersproject/providers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { message } from "antd";
import { createContext, ReactChild, ReactChildren, useCallback, useContext, useEffect, useState } from "react";
import { useAsync } from "react-use";
import Web3Modal from "web3modal";
import { getNetworkById } from "../constants/network";
import { setRpcUrl as setLsRpcUrl } from "../helpers/env";

const targetNetworkId = Number(process.env.REACT_APP_NETWORK_ID) || 883;

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  network: "mainnet",
  providerOptions: {
    injected: {
      display: {
        // logo: "data:image/gif;base64,INSERT_BASE64_STRING",
        name: "Injected",
        description: "Connect with the provider in your Browser",
      },
      package: null,
    },
    // Example with WalletConnect provider
    walletconnect: {
      display: {
        // logo: "data:image/gif;base64,INSERT_BASE64_STRING",
        name: "Mobile",
        description: "Scan qrcode with your mobile wallet",
      },
      package: WalletConnectProvider,
      options: {
        infuraId: "INFURA_ID", // required
      },
    },
  },
  theme: "dark",
});

interface Web3ContextProps {
  provider: Provider | undefined;
  signer: JsonRpcSigner | undefined;
  network: string;
  address: string;
  rpcUrl: string;
  chainId: number;
  web3Modal: Web3Modal;
  connecting: boolean;
  loadWeb3Modal: (providerName: string) => Promise<void>;
  logoutOfWeb3Modal: () => Promise<void>;
}

interface Web3ContextProviderProps {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

export const Web3Context = createContext<Web3ContextProps>({
  provider: undefined,
  signer: undefined,
  address: "",
  rpcUrl: "",
  network: getNetworkById(targetNetworkId).name,
  chainId: 0,
  web3Modal,
  connecting: false,
  loadWeb3Modal: async () => {},
  logoutOfWeb3Modal: async () => {},
});

export const Web3ContextProvider = ({ children }: Web3ContextProviderProps): JSX.Element => {
  const networkObject = getNetworkById(targetNetworkId);
  const [provider, setProvider] = useState<Provider>();
  const [signer, setSigner] = useState<JsonRpcSigner>();
  const [network, setNetwork] = useState(networkObject.name);
  const [address, setAddress] = useState("");
  const [rpcUrl, setRpcUrl] = useState(networkObject.rpcUrl);
  const [chainId, setChainId] = useState(0);
  const [connecting, setConnecting] = useState(false);
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const [web3Connection, setWeb3Connection] = useState<any>();

  useAsync(async () => {
    if (!web3Connection) {
      return;
    }
    web3Connection.on("accountsChanged", () => {
      window.location.reload();
    });
    web3Connection.on("chainChanged", () => {
      window.location.reload();
    });
  }, [web3Connection]);

  useAsync(async () => {
    if (!provider) {
      return;
    }
    const networkData = await provider.getNetwork();
    setChainId(networkData.chainId);
    setNetwork(networkData.name);
    const url = getNetworkById(Number(networkData.chainId)).rpcUrl;
    setRpcUrl(url);
    setLsRpcUrl(url);
  }, [provider]);

  const loadWeb3Modal = useCallback(async (providerName: string) => {
    setConnecting(true);
    const connection = await web3Modal.connectTo(providerName).catch(e => {
      console.error(e);
      setConnecting(false);
    });

    if (!connection) {
      message.error("connection failed!");
      return;
    }
    setConnecting(false);
    setWeb3Connection(connection);
    // TODO: Remove this workaround when possible
    if (connection.isImToken) {
      connection.request = undefined;
    }
    const newProvider = new Web3Provider(connection);
    setProvider(newProvider);
    const newSigner = newProvider.getSigner();
    setSigner(newSigner);
    const adrs = await newSigner.getAddress();
    setAddress(adrs);
  }, []);
  const logoutOfWeb3Modal = useCallback(async () => {
    if (web3Connection && web3Connection.close) {
      web3Connection.close();
    }
    web3Modal.clearCachedProvider();
    window.location.reload();
  }, [web3Connection]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal(web3Modal.cachedProvider);
    }
  }, [loadWeb3Modal]);

  return (
    <Web3Context.Provider
      value={{
        provider,
        signer,
        network,
        address,
        rpcUrl,
        chainId,
        web3Modal,
        connecting,
        loadWeb3Modal,
        logoutOfWeb3Modal,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

export const useWeb3Context: () => Web3ContextProps = () => useContext(Web3Context);
