import { BigNumberish } from "ethers";
import React, { FC } from "react";
import { useTheme } from "react-jss";
import { gt, lt } from "../helpers/numbers";
import { Theme } from "../theme/theme";

interface IProps {
  type?: "win" | "lose" | "default" | BigNumberish;
  size?: number;
}

const Gain: FC<IProps> = props => {
  const theme = useTheme<Theme>();
  const { children, type, size = 14 } = props;

  let color;
  if (!type) {
    color = theme.fontColorSecondary;
  }
  if (typeof type === "number") {
    if (type > 0) {
      color = theme.win;
    } else if (type < 0) {
      color = theme.lose;
    } else {
      color = theme.fontColorSecondary;
    }
  } else if (type === "win") {
    color = theme.win;
  } else if (type === "lose") {
    color = theme.lose;
  } else if (type === "default") {
    color = theme.fontColorSecondary;
  } else if (gt(type, 0)) {
    color = theme.win;
  } else if (lt(type, 0)) {
    color = theme.lose;
  } else {
    color = theme.fontColorSecondary;
  }

  return <span style={{ color, fontSize: size }}>{children}</span>;
};

export default Gain;
