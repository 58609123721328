import { solidityKeccak256 } from "ethers/lib/utils";
import { components } from "../api/api";
import { TnType } from "../constants/tnType";

export const hashStrategyRequest = (req: components["schemas"]["StrategyOperationParams"]) => {
  return solidityKeccak256(
    ["uint8", "uint32", "uint256", "uint128", "uint128", "uint64"],
    [req.type, req.strategyId, req.amount, req.priceLimit, req.fee, req.timestamp],
  );
};

export const hashWithdrawRequest = (req: components["schemas"]["WithdrawOperationParams"]) => {
  return solidityKeccak256(
    ["uint8", "address", "uint32", "uint256", "uint128", "uint64"],
    [TnType.Withdraw, req.receiver.toLowerCase(), req.assetId, req.assetAmount, req.fee, req.timestamp],
  );
};

export const hashFarmingRequest = (req: components["schemas"]["FarmingOperationParams"]) => {
  return solidityKeccak256(
    ["uint8", "uint32", "uint256", "uint128", "uint64"],
    [req.type, req.poolId, req.shares, req.fee, req.timestamp],
  );
};
