import { FC } from "react";
import { createUseStyles } from "react-jss";
import { components } from "../api/api";
import { formatDecimal } from "../helpers/format";
import { Theme } from "../theme/theme";
import TokenGain from "./TokenGain";

const useStyles = createUseStyles<string, unknown, Theme>(_ => {
  return {
    miningRewards: {
      display: "flex",
      flexDirection: "column",
    },
  };
});

interface IMiningRewardsProps {
  rewards: components["schemas"]["RewardAsset"][];
}

const MiningRewards: FC<IMiningRewardsProps> = props => {
  const classes = useStyles();
  const { rewards } = props;

  return (
    <div className={classes.miningRewards}>
      {rewards.map(reward => {
        return (
          <TokenGain
            key={reward.assetId}
            formattedAmount={formatDecimal(reward.amount, reward.assetDecimal)}
            symbol={reward.assetSymbol}
          />
        );
      })}
    </div>
  );
};

export default MiningRewards;
