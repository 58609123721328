import _ from "lodash";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { components } from "../../api/api";
import { formatBalance, formatDecimal } from "../../helpers/format";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { fetchInvestmentHistory } from "../../redux/investmentHistorySlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { Theme, ThemeType } from "../../theme/theme";
import { ClockCustom } from "../customIcons";
import CustomTable from "../CustomTable";
import LabelWithPopover from "../LabelWithPopover";
import Loading from "../Loading";
import PageFlipper from "../PageFlipper";
import TokenGain from "../TokenGain";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    // margin: theme.hismainMargin,
  },
  mainText: {
    fontSize: theme.fontSizeL,
  },
  subText: {
    color: theme.fontColorSecondary,
    fontSize: theme.fontSizeS,
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
  },
  mainchainHistory: {
    position: "relative",
  },
  historyList: {},
  historyListItem: {
    borderBottom: theme.border,
  },
  itemOne: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 18,
    "&:last-child": {
      paddingBottom: 20,
    },
  },
  ListItemLeft: {
    textAlign: "left",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  ListItemRight: {
    textAlign: "right",
    fontSize: "16px",
    color: "#fff",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  itemTopname: {
    fontSize: "14px",
  },
  itemTopdesp: {
    fontSize: "14px",
    color: "#fff",
    lineHeight: 1,
  },
  itemTopval: {
    fontSize: "14px",
    color: "#00D395",
    marginLeft: 8,
    lineHeight: 1,
  },
  itemBottom: {
    fontSize: "12px",
    color: "#c4c4c4",
    marginTop: 4,
    lineHeight: 1,
  },
  infoText: {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    justifyContent: "flex-end",
  },
}));

export default function StrategyHistory(): JSX.Element {
  const classes = useStyles();
  const { address } = useWeb3Context();
  const { entries, hasMore, loading, currentPage } = useAppSelector(state => state.investmentHistory);
  const dispatch = useAppDispatch();
  const theme = useTheme<Theme>();
  const isMobile = theme.type === ThemeType.S;

  const fetchPage = useCallback(
    (page: number) => {
      if (!address) {
        return;
      }
      dispatch(fetchInvestmentHistory({ address, page }));
    },
    [address, dispatch],
  );

  // on load init histories
  useEffect(() => {
    fetchPage(0);
  }, [fetchPage]);

  const mapDataToCols = useCallback((entry: components["schemas"]["InvestmentHistoryEntry"]) => {
    return {
      key: entry.tnId,
      title: entry.strategyName,
      strategyToken: {
        action: entry.action,
        amount: formatDecimal(entry.shareAmt, entry.shareDecimal),
        symbol: entry.shareName,
        status: entry.status,
      },
      assetToken: {
        action: entry.action,
        amount: formatDecimal(entry.assetAmt, entry.assetDecimal),
        symbol: entry.assetSymbol,
        status: entry.status,
      },
      fee: {
        feeAmt: formatDecimal(entry.feeTokenAmt, entry.feeDecimal),
        feeToken: entry.feeTokenName,
      },
      status: {
        action: entry.action,
        status: entry.status,
        time: entry.executeTimestamp && entry.executeTimestamp !== 0 ? entry.executeTimestamp : entry.acceptTimestamp,
      },
    };
  }, []);
  const changeStatus = useCallback(
    record => {
      const epoch = _.toNumber(record.status.time);
      let timeStr = "--";
      if (epoch !== 0) {
        timeStr = new Date(epoch).toLocaleString();
      }
      let statusText: string | ReactNode;
      switch (record.status.status) {
        case "PENDING":
          statusText =
            record.status.action === "FUND" ? (
              <LabelWithPopover label="Deposit Pending" placement="topRight">
                This transaction is currently waiting for the next aggregated fund allocation execution, which may take
                up to {process.env.REACT_APP_CONFIRM_WINDOW}.
              </LabelWithPopover>
            ) : (
              <LabelWithPopover label="Withdraw Pending" placement="topRight">
                This transaction is currently waiting for the next aggregated fund allocation execution, which may take
                up to {process.env.REACT_APP_CONFIRM_WINDOW}.
              </LabelWithPopover>
            );
          break;
        case "COMPLETED":
          statusText = record.status.action === "FUND" ? "Deposit Completed" : "Withdraw Completed";
          break;
        case "EXECUTED":
          statusText = record.status.action === "FUND" ? "Deposit Executed" : "Withdraw Executed";
          break;
        case "REVERTED":
          statusText = (
            <LabelWithPopover label="Reverted" placement="topRight">
              This transaction has been reverted because the strategy token price slippage exceeded the max tolerance.
              Your fund has been returned.
            </LabelWithPopover>
          );
          break;
        default:
          statusText = "Error";
      }
      return (
        <div>
          <div style={{ fontWeight: "normal" }}>{statusText}</div>
          <div className={classes.subText}>{timeStr}</div>
        </div>
      );
    },
    [classes.subText],
  );
  type Cols = ReturnType<typeof mapDataToCols>;

  const columns = useMemo(
    () => [
      {
        title: "Strategy",
        dataIndex: "title",
        render: (title: string) => {
          return <span className={classes.mainText}>{title}</span>;
        },
      },
      {
        title: (
          <LabelWithPopover label="Strategy Token">
            Strategy Token (stToken) is your yield-bearing liquidity certificate that can later be used to redeem the
            underlying liquidity along with yields back to your available L2 balance.
          </LabelWithPopover>
        ),
        dataIndex: "strategyToken",
        render: ({ amount, symbol, action, status }: Cols["strategyToken"]) => {
          const sign = action === "FUND" ? "" : "-";
          const formattedAmt = formatBalance(sign + amount);

          return status !== "PENDING" || action !== "FUND" ? (
            <div>
              <div>
                <TokenGain formattedAmount={formattedAmt} symbol={symbol} />
              </div>
            </div>
          ) : (
            <div>
              <div>
                <LabelWithPopover
                  placement="topRight"
                  label={<TokenGain prefix="≈" formattedAmount={formattedAmt} symbol={symbol} />}
                >
                  This is the approximate stToken amount you will receive after the max fee is deducted, estimated based
                  on the current stToken price. Actual amount may vary depending on the stToken price and the actual fee
                  when this transaction is executed.
                </LabelWithPopover>
              </div>
            </div>
          );
        },
      },
      {
        title: (
          <LabelWithPopover label="Asset Token">
            Asset Token is the original token you have in your wallet asset such as DAI, CELR, WETH.
          </LabelWithPopover>
        ),
        dataIndex: "assetToken",
        render: ({ amount, symbol, action, status }: Cols["assetToken"]) => {
          const sign = action === "FUND" ? "-" : "";
          const formattedAmt = formatBalance(sign + amount);

          return status !== "PENDING" || action === "FUND" ? (
            <div>
              <div>
                <TokenGain formattedAmount={formattedAmt} symbol={symbol} />
              </div>
            </div>
          ) : (
            <div>
              <div>
                <LabelWithPopover
                  placement="topRight"
                  label={<TokenGain prefix="≈" formattedAmount={formattedAmt} symbol={symbol} />}
                >
                  This is the approximate {symbol} amount you will receive after the max fee is deducted, estimated
                  based on the current stToken price. Actual amount may vary depending on the stToken price and the
                  actual fee when this transaction is executed.
                </LabelWithPopover>
              </div>
            </div>
          );
        },
      },
      {
        title: (
          <LabelWithPopover label="Fee">
            There is a processing fee for each transaction in order to cover the operation cost of Layer2.Finance.
          </LabelWithPopover>
        ),
        dataIndex: "fee",
        render: (e, record) => {
          if (record.fee.feeAmt === "0.0") {
            return "--";
          }

          const displayFee = record.fee.feeAmt + " " + record.fee.feeToken;
          return record.status.status !== "PENDING" ? (
            displayFee
          ) : (
            <LabelWithPopover label={displayFee} placement="topRight">
              This is the max processing fee charged for this transaction to cover the operation cost. Note that the
              actual fee may be smaller when this transaction is executed.
            </LabelWithPopover>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (e, record) => changeStatus(record),
      },
    ],
    [changeStatus, classes.mainText],
  );

  const mobileList = entries ? entries.map(mapDataToCols) : [];

  return (
    <div className={classes.container}>
      {isMobile ? (
        <div className={classes.mainchainHistory}>
          <Loading
            loading={loading}
            emptyIcon={<ClockCustom />}
            emptyDescription="No L2 transaction history yet"
            isEmpty={!entries?.length}
          >
            <div className={classes.historyList}>
              {mobileList.map(item => {
                return (
                  <div className={classes.historyListItem} key={item.key}>
                    <div className={classes.itemOne}>
                      <div className={classes.ListItemLeft}>
                        <div>
                          <span className={classes.itemTopname}>{item.title}</span>
                        </div>
                      </div>
                      <div className={classes.ListItemRight}>{changeStatus(item)}</div>
                    </div>
                    <div className={classes.itemOne}>
                      <div className={classes.ListItemLeft}>
                        <div>
                          <span className={classes.itemTopdesp}>
                            <LabelWithPopover label="Strategy Token" placement="topLeft">
                              Strategy Token (stToken) is your yield-bearing liquidity certificate that can later be
                              used to redeem the underlying liquidity along with yields back to your available L2
                              balance.
                            </LabelWithPopover>
                          </span>
                        </div>
                      </div>
                      <div className={classes.ListItemRight}>
                        {(() => {
                          const sign = item.strategyToken.action === "FUND" ? "" : "-";
                          const formattedAmt = formatBalance(sign + item.strategyToken.amount);
                          return (
                            <div className={classes.infoText}>
                              <TokenGain formattedAmount={formattedAmt} symbol={item.strategyToken.symbol} />
                            </div>
                          );
                        })()}
                      </div>
                    </div>
                    <div className={classes.itemOne}>
                      <div className={classes.ListItemLeft}>
                        <div>
                          <span className={classes.itemTopdesp}>
                            <LabelWithPopover label="Asset Token" placement="topLeft">
                              Asset Token is the original token you have in your wallet asset such as DAI, CELR, WETH.
                            </LabelWithPopover>
                          </span>
                        </div>
                      </div>
                      <div className={classes.ListItemRight}>
                        {(() => {
                          const sign = item.assetToken.action === "FUND" ? "-" : "";
                          const formattedAmt = formatBalance(sign + item.assetToken.amount);
                          return (
                            <div className={classes.infoText}>
                              <TokenGain formattedAmount={formattedAmt} symbol={item.assetToken.symbol} />
                            </div>
                          );
                        })()}
                      </div>
                    </div>
                    <div className={classes.itemOne}>
                      <div className={classes.ListItemLeft}>
                        <div>
                          <span className={classes.itemTopdesp}>
                            <LabelWithPopover label="Fee" placement="topLeft">
                              There is a processing fee for each transaction in order to cover the operation cost of
                              Layer2.Finance.
                            </LabelWithPopover>
                          </span>
                        </div>
                      </div>
                      <div className={classes.ListItemRight}>
                        <div className={classes.infoText}>
                          {item.fee.feeAmt} {item.fee.feeToken}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {currentPage !== undefined ? (
              <div className={classes.pagination}>
                <PageFlipper
                  page={currentPage}
                  hasMore={hasMore}
                  onPageChange={(toPage: number) => fetchPage(toPage)}
                />
              </div>
            ) : null}
          </Loading>
        </div>
      ) : (
        <Loading
          loading={loading}
          emptyIcon={<ClockCustom />}
          emptyDescription="No L2 transaction history yet"
          isEmpty={!entries?.length}
        >
          <CustomTable
            dataSource={entries.map(mapDataToCols)}
            columns={columns}
            loading={loading}
            currentPage={currentPage}
            hasMore={hasMore}
            onPageChange={(toPage: number) => fetchPage(toPage)}
          />
        </Loading>
      )}
    </div>
  );
}
