import { ButtonType } from "antd/lib/button";
import { formatUnits } from "ethers/lib/utils";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router";

import { MaxButton } from "../..";
import { strategyOperation } from "../../../api/strategy";
import { formatDecimal, formatPercentage } from "../../../helpers/format";
import { gte, lte } from "../../../helpers/numbers";
import { hashStrategyRequest } from "../../../helpers/requestHashers";
import { getRedeemedAmountAfterSell } from "../../../helpers/strategy";
import { IValidator, useTokenInputState } from "../../../helpers/tokenAmountState";
import { useTransitionOperator } from "../../../hooks/operation/transitionOperator";
import { useWeb3Context } from "../../../providers/Web3ContextProvider";
import { useAppDispatch } from "../../../redux/store";
import { fetchStrategies } from "../../../redux/strategySlice";
import LabelWithPopover from "../../LabelWithPopover";
import { ITokenInputChangeEvent } from "../../TokenInput";
import ActionModal from "../common/ActionModal";
import ActionTitle from "../common/ActionTitle";
import ModalExtraInfoRow from "../common/ModalExtraInfoRow";
import ModalResult from "../common/ModalResult";
import ModalTokenInput from "../common/ModalTokenInput";
import { FundExitModalProps } from "./FundModal";

export default function ExitModal({ token, strategy, onClose }: FundExitModalProps): JSX.Element {
  const { address } = useWeb3Context();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const minExit = useMemo(
    () => formatDecimal(strategy.minExit, strategy.shareDecimal, strategy.shareDecimal),
    [strategy],
  );
  const validators: IValidator[] = useMemo(
    () => [
      {
        validator: amtBig => gte(amtBig, strategy.minExit),
        message: `Please input a number larger than ${minExit}`,
      },
      {
        validator: amtBig => gte(amtBig, strategy.maxWithdrawFee?.amount),
        message: `Please enter an amount larger than fee`,
      },
      {
        validator: amtBig => lte(amtBig, strategy.shareAmt),
        message: `You don’t have enough strategy token to withdraw`,
      },
    ],
    [minExit, strategy],
  );
  const [amount, setAmount] = useTokenInputState(token.decimal, undefined, validators);

  const {
    signAndOperate,
    operation,
    resetOperationState: reset,
  } = useTransitionOperator(strategyOperation, hashStrategyRequest);

  const handleAction = async () => {
    signAndOperate({
      type: 5, // Sell
      strategyId: strategy.id as number,
      amount: amount.big.toString(), // stToken amount
      timestamp: Date.now(),
      priceLimit: strategy.minSharePriceForSell as string,
      fee: strategy.maxDepositFee?.amount as string,
      signature: "",
    });
  };

  const handleModalClose = useCallback(() => {
    if (operation.completed) {
      dispatch(fetchStrategies({ address, tokenId: token.id }));
      reset();
    }
    onClose();
  }, [operation.completed, onClose, dispatch, address, token.id, reset]);

  const handleTokenInputChange = (e: ITokenInputChangeEvent) => {
    setAmount(e.value);
  };

  const strategyNameRow = <ModalExtraInfoRow key={1} left="Strategy Name" right={strategy.name} />;
  const withdrawAmountRow = (
    <ModalExtraInfoRow key={2} left="Withdraw Amount" right={`${amount.formatted} ${strategy.shareName}`} />
  );
  const maxFeeRow = (
    <ModalExtraInfoRow
      key={3}
      left={
        <LabelWithPopover label="Max Fee">
          This the max processing fee charged for this transaction to cover the operation cost. Note that the actual fee
          may be smaller when this transaction is executed.
        </LabelWithPopover>
      }
      right={`${formatDecimal(strategy.maxWithdrawFee?.amount, strategy.maxWithdrawFee?.decimal)}
      ${strategy.maxWithdrawFee?.symbol}`}
    />
  );
  const youReceiveRow = (
    <ModalExtraInfoRow
      key={4}
      left={
        <LabelWithPopover label="You Receive">
          This is the approximate {token.symbol} amount you will receive after the max fee is deducted, estimated based
          on the current stToken price. The actual {token.symbol} amount you will receive may vary depending on the
          stToken price and the actual processing fee when this transaction is executed (up to $
          {process.env.REACT_APP_CONFIRM_WINDOW} later).
        </LabelWithPopover>
      }
      right={`≈ ${formatDecimal(getRedeemedAmountAfterSell(amount.big, strategy), token.decimal)} ${token.symbol}`}
    />
  );
  const slippageTolerance = (
    <ModalExtraInfoRow
      key={5}
      left={
        <LabelWithPopover label="Slippage Tolerance">
          Due to the fluctuation of strategy token price, the actual {token.symbol} amount you will receive might be
          smaller than the estimated amount you see above. If the relative difference is larger than{" "}
          {formatPercentage(strategy.withdrawSlippageTolerance)}, the transaction will be reverted.
        </LabelWithPopover>
      }
      right={formatPercentage(strategy.withdrawSlippageTolerance)}
    />
  );

  let content;
  let action: () => void;
  let actionText: string;
  let buttonType: ButtonType;
  let extra;

  if (operation.completed) {
    content = (
      <ModalResult
        title="Withdrawal has been submitted"
        description={
          <span>
            It may take up to {process.env.REACT_APP_CONFIRM_WINDOW} to finalize your withdrawal from the strategy. You
            can check the progress in the history page.
          </span>
        }
      />
    );
    action = () => history.push("/history/investment");
    actionText = "Check History";
    buttonType = "link";
    extra = [strategyNameRow, withdrawAmountRow, maxFeeRow, youReceiveRow, slippageTolerance];
  } else {
    content = (
      <ModalTokenInput
        description="You can withdraw your asset from this strategy. The withdrawn assets will be credited back to your available L2 balance which can be committed to another strategy or withdrawn to L1."
        amount={amount.input}
        maxButton={
          <LabelWithPopover
            placement="top"
            label={
              <div style={{ display: "inline-block" }}>
                <MaxButton
                  onClick={() => setAmount(formatUnits(strategy.shareAmt || "0", strategy.shareDecimal))}
                  value={formatDecimal(strategy.shareAmt, token.decimal)}
                  symbol={strategy.shareName}
                />
              </div>
            }
          >
            Strategy Token (stToken) is your liquidity token that can be used to redeem the underlying asset along with
            yields back to your available L2 balance.
          </LabelWithPopover>
        }
        symbol={strategy.shareName}
        onChange={handleTokenInputChange}
        bottomDescription={`Minimal Withdraw Amount: ${minExit} ${strategy.shareName}`}
      />
    );
    action = handleAction;
    actionText = "Withdraw";
    buttonType = "primary";
    extra = [strategyNameRow, maxFeeRow, youReceiveRow, slippageTolerance];
  }

  return (
    <ActionModal
      visible
      title={<ActionTitle title="Withdraw from Strategy" token={token} />}
      actionText={actionText}
      actionDisabled={!amount.input}
      errMsg={amount.error}
      onCancel={handleModalClose}
      onAction={action}
      actionLoading={operation.loading}
      buttonType={buttonType}
      extra={extra}
    >
      {content}
    </ActionModal>
  );
}
