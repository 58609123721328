import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/lib/integration/react";
import { TrackJS } from "trackjs";
import App from "./app/App";
import "./index.css";
import { ContractsContextProvider } from "./providers/ContractsContextProvider";
import { Web3ContextProvider } from "./providers/Web3ContextProvider";
import store, { persistor } from "./redux/store";

TrackJS.install({
  token: "ab3f20acb2324c34b6888956bbfb736d",
  // for more configuration options, see https://docs.trackjs.com
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HashRouter>
        <Web3ContextProvider>
          <ContractsContextProvider>
            <App />
          </ContractsContextProvider>
        </Web3ContextProvider>
      </HashRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root"),
);
