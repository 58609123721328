import { Card, Menu } from "antd";
import React, { FC, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Media } from "../theme";
import { Theme, ThemeType } from "../theme/theme";
import ReloadButton from "./ReloadButton";

const useStyles = createUseStyles<string, { tabWidthPercentage: number }, Theme>(theme => ({
  container: {
    margin: theme.type === ThemeType.S ? "0 -12px" : undefined,
    position: "relative",
    borderRadius: theme.type === ThemeType.S ? 24 : "auto",
  },
  menu: {
    background: "none",
    display: "flex",
    alignItems: "center",
    margin: "0 -14px",
    lineHeight: 1,
    "& .ant-menu-item a": {
      color: "rgba(255, 255, 255, 0.4)",
      fontWeight: theme.fontWeightBold,
      fontSize: 14,
      display: "inline-block",
      paddingBottom: 14,
      "&:hover": {
        color: theme.fontColorSecondary,
      },
    },
    "& .ant-menu-item-selected a": {
      color: theme.fontColorPrimary,
    },
  },
  tab: {
    lineHeight: 1.2,
  },
  stackedWords: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  reloadButton: {
    position: "absolute",
    top: 12,
    right: 12,
    background: theme.bgColorPrimary,
  },
  [Media.S]: {
    menu: {
      "&.ant-menu": {
        paddingTop: 12,
      },
      "& .ant-menu-item": {
        width: ({ tabWidthPercentage }) => tabWidthPercentage + "%",
        display: "flex",
        justifyContent: "center",
        padding: 12,
      },
    },
    reloadButton: {
      top: -42,
      right: 12,
    },
  },
}));

export interface IRoute {
  path: string;
  title: string;
  component: React.ReactNode;
  onReload?: () => void;
}

interface IProps {
  routes: IRoute[];
  redirect?: {
    from: string;
    to: string;
  };
}

const RouteTabbedCard: FC<IProps> = props => {
  const { routes, redirect } = props;
  const tabWidthPercentage = 100 / routes.length;
  const theme = useTheme<Theme>();
  const classes = useStyles({ tabWidthPercentage });
  const location = useLocation();
  const [route, setRoute] = useState("");
  const [selectedRoute, setSelectedRoute] = useState<IRoute>();

  useEffect(() => {
    const segments = location.pathname.split("/").filter(p => p);
    setRoute(segments[segments.length - 1]);
    setSelectedRoute(routes.find(r => r.path === location.pathname));
    window.scrollTo(0, 0);
  }, [location, routes]);

  const cardBodyStyle = {
    padding: theme.cardPaddingXL,
    background: theme.bgColorSecondary,
    borderRadius: theme.borderRadiusXL,
  };

  const renderMenuTitle = (title: string) =>
    theme.type === ThemeType.S ? (
      <div className={classes.stackedWords}>
        {title.split(" ").map(word => (
          <span key={word}>{word} </span>
        ))}
      </div>
    ) : (
      title
    );

  return (
    <Card bodyStyle={cardBodyStyle} className={classes.container}>
      {selectedRoute?.onReload && <ReloadButton onClick={selectedRoute.onReload} className={classes.reloadButton} />}
      <Menu className={classes.menu} selectedKeys={[route]} mode="horizontal" disabledOverflow>
        {routes.map(r => {
          const segments = r.path.split("/");
          const key = segments[segments.length - 1];
          return (
            <Menu.Item key={key} className={classes.tab}>
              <Link to={r.path}>{renderMenuTitle(r.title)}</Link>
            </Menu.Item>
          );
        })}
      </Menu>
      <Switch>
        {routes.map(r => {
          return (
            <Route key={r.title} path={r.path}>
              {r.component}
            </Route>
          );
        })}
        {redirect && <Redirect exact from={redirect.from} to={redirect.to} />}
      </Switch>
    </Card>
  );
};

export default RouteTabbedCard;
