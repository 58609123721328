import { BigNumber } from "@ethersproject/bignumber";
import { RollupChain } from "../typechain/RollupChain";

export const getNetDepositOfToken = (rollupChain?: RollupChain, tokenAddress?: string) => {
  if (!rollupChain || !tokenAddress) {
    return Promise.resolve(BigNumber.from(0));
  }
  return rollupChain.netDeposits(tokenAddress);
};

export const getDepositLimitOfToken = (rollupChain?: RollupChain, tokenAddress?: string) => {
  if (!rollupChain || !tokenAddress) {
    return Promise.resolve(BigNumber.from(0));
  }
  return rollupChain.netDepositLimits(tokenAddress);
};

export const checkDepositLimit = async (rollupChain?: RollupChain, tokenAddress?: string, offset?: BigNumber) => {
  const limitPromise = getDepositLimitOfToken(rollupChain, tokenAddress);
  const netDepositPromise = getNetDepositOfToken(rollupChain, tokenAddress);
  const promises = await Promise.all([limitPromise, netDepositPromise]).catch(e => console.log("error", e));
  const limit = promises[0];
  const netDeposit = promises[1];
  let isDepositLimitReached: boolean;
  if (offset) {
    isDepositLimitReached = netDeposit.add(offset).gte(limit);
  } else {
    isDepositLimitReached = netDeposit.gte(limit);
  }
  return {
    isDepositLimitReached,
    limit,
    netDeposit,
  };
};
