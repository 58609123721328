import { Typography } from "antd";
import { FC, ReactNode } from "react";
import { createUseStyles } from "react-jss";
import { Theme } from "../theme/theme";

interface IProps {
  title: ReactNode;
  titleColor?: string;
  subtitle: ReactNode;
  className?: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
  title: {
    fontSize: theme.fontSizeL,
  },
  subtitle: {
    color: theme.fontColorSecondary,
    fontSize: theme.fontSizeM,
  },
}));

const TitleSubtitle: FC<IProps> = props => {
  const { title, subtitle, titleColor, className } = props;
  const classes = useStyles();
  return (
    <div className={className}>
      <Typography className={classes.title} style={{ color: titleColor }}>
        {title}
      </Typography>
      {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
    </div>
  );
};

export default TitleSubtitle;
