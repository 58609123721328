// stolen from WoW's Elvui strata setting XD
export enum Strata {
  BACKGROUND = 0,
  LOW = 1000,
  MEDIUM = 2000,
  HIGH = 3000,
  DIALOG = 4000,
  FULLSCREEN = 5000,
  FULLSCREEN_DIALOG = 6000,
  TOOLTIP = 7000,
}
