import { Button, Col, Row, Space, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useToggle } from "react-use";

import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { BigNumber } from "@ethersproject/bignumber";
import { hexlify } from "@ethersproject/bytes";

import { checkDepositLimit } from "../api/rollupChain";
import { formatDecimal } from "../helpers/format";
import { add, eq } from "../helpers/numbers";
import { useCustomContractLoader, useEthBalance, useTokenBalance } from "../hooks";
import { useContractsContext } from "../providers/ContractsContextProvider";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import { fetchAssets } from "../redux/assetSlice";
import { ModalName, openModal } from "../redux/modalSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { fetchStrategies } from "../redux/strategySlice";
import { Media } from "../theme";
import { Theme, ThemeType } from "../theme/theme";
import { ERC20 } from "../typechain/ERC20";
import { ERC20__factory } from "../typechain/factories/ERC20__factory";
import { AngleCustom } from "./customIcons";
import LabelWithPopover from "./LabelWithPopover";
import { ActionModal, DepositETHModal, DepositModal, WithdrawModal } from "./modals";
import ReloadButton from "./ReloadButton";
import SingleLineSkeleton from "./SingleLineSkeleton";
import TokenGain from "./TokenGain";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    background: "#303234",
    padding: theme.type === ThemeType.S ? 16 : "0 24px 24px 24px",
    borderRadius: theme.type === ThemeType.S ? 24 : 0,
    position: "relative",
  },
  listHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.type === ThemeType.S ? 16 : undefined,
    padding: theme.type === ThemeType.S ? undefined : 24,
    background: theme.type === ThemeType.S ? undefined : "#303234",
    fontSize: theme.fontSizeM,
  },
  top: {
    display: theme.xsFlex,
    marginBottom: theme.type === ThemeType.S ? 20 : 34,
  },
  topRight: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginTop: 2,
  },
  bottom: {
    display: theme.xsFlex,
  },
  loginHeader: {
    display: "flex",
    margin: 20,
  },
  balance: {
    fontSize: 20,
    margin: 0,
  },
  balanceLgTitle: {
    color: theme.fontColorSecondary,
    fontSize: theme.fontSizeL,
  },
  balanceLg: {
    color: theme.fontColorPrimary,
    fontSize: theme.fontSizeXL,
    fontWeight: "bold",
  },
  balanceUnit: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: theme.balanceUnit.height,
    borderBottom: theme.type === ThemeType.S ? theme.border : undefined,
    lineHeight: theme.balanceUnit.lineHeight,
    marginRight: theme.balanceUnit.marginRight,
    "&:last-child": {
      marginRight: 0,
    },
  },
  balanceSmTitle: {
    color: theme.fontColorSecondary,
  },
  balanceSm: {
    color: theme.fontColorPrimary,
  },
  buttonGroup: {
    marginLeft: "auto",
    "@global": {
      ".ant-btn": {
        fontSize: theme.fontSizeM,
        fontWeight: theme.fontWeightBold,
        width: "100%",
        borderRadius: 12,
      },
    },
  },
  valueDetail: {
    position: "absolute",
    top: "16px",
    right: "20px",
  },
  reloadButton: {},
  [Media.S]: {
    balanceUnit: {
      flexDirection: "row",
    },
    container: {
      background: theme.bgColorSecondary,
    },
    reloadButton: {
      "&.ant-btn": {
        background: theme.bgColorPrimary,
      },
    },
  },
}));

export default function TokenDetail(): JSX.Element {
  // styles
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const isMobile = theme.type === ThemeType.S;

  // redux related
  const dispatch = useAppDispatch();
  const { asset, strategy } = useAppSelector(state => state);
  const { assets, selectedIndex, loading: loadingAssets } = asset;
  const { loading: loadingStrategies, totalEarning, totalInvestmentValue, totalStakedValue } = strategy;
  const token = assets[selectedIndex];

  const investmentValue = useMemo(
    () => add(totalInvestmentValue, totalStakedValue),
    [totalInvestmentValue, totalStakedValue],
  );

  // token/contract related states
  const { signer, address, provider } = useWeb3Context();
  const {
    contracts: { rollupChain },
  } = useContractsContext();
  const tokenContract = useCustomContractLoader(
    provider,
    (token.address ? hexlify(token.address) : token.address) || "",
    ERC20__factory,
  ) as ERC20 | undefined;
  const [tokenBal, tokenBalLoading, , reloadTokenBal] = useTokenBalance(tokenContract, address);
  const [ethBal, ethBalLoading, , reloadEthBal] = useEthBalance(provider, address);
  // local states
  const [showDeposit, toggleDeposit] = useToggle(false);
  const [showWithdraw, toggleWithdraw] = useToggle(false);
  const [showValueDetail, toggleValueDetail] = useToggle(false);
  const [checkingDepositLimit, setCheckingDepositLimit] = useState(false);
  const [error, setError] = useState("");

  const reloadAll = () => {
    dispatch(fetchStrategies({ address, tokenId: token.id }));
    dispatch(fetchAssets(address));
    reloadTokenBal();
    reloadEthBal();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showDeposit, showWithdraw]);

  const minDeposit = useMemo(() => {
    if (strategy.strategies.length) {
      const ret = strategy.strategies.reduce((minFund, strat) => {
        const stratMinFund = BigNumber.from(strat.minFund || 0);
        return stratMinFund.lt(minFund) ? stratMinFund : minFund;
      }, BigNumber.from(strategy.strategies[0].minFund));
      return formatDecimal(ret, token.decimal);
    }
    return undefined;
  }, [strategy.strategies, token.decimal]);

  let depositModal: JSX.Element;
  let l1Balance: BigNumber;
  if (token.symbol === "ETH") {
    depositModal = <DepositETHModal token={token} onClose={toggleDeposit} minDeposit={minDeposit} onError={setError} />;
    l1Balance = ethBal;
  } else {
    depositModal = <DepositModal token={token} onClose={toggleDeposit} minDeposit={minDeposit} onError={setError} />;
    l1Balance = tokenBal;
  }

  if (!signer) {
    return (
      <div className={classes.container}>
        <div className={classes.listHeader}>
          {signer && <Typography.Title level={5}>My {token.name} Assets Overview</Typography.Title>}
        </div>
        <Space className={classes.loginHeader} direction="vertical" align="center">
          <div>Connect your wallet to start</div>
          <Button type="primary" onClick={() => dispatch(openModal(ModalName.provider))}>
            Connect to Wallet
          </Button>
        </Space>
      </div>
    );
  }

  const handleDepositClick = async () => {
    setCheckingDepositLimit(true);
    try {
      const result = await checkDepositLimit(rollupChain, token.address);
      if (result.isDepositLimitReached) {
        setError(`Sorry the ${token.symbol} pool currently doesn’t accept new deposits as the maximum pool cap has been reached.
        The cap will be raised soon. Please stay tuned.`);
      } else {
        toggleDeposit();
      }
    } finally {
      setCheckingDepositLimit(false);
    }
  };

  return (
    <div>
      <div className={classes.listHeader}>
        <h3>My {token.name} Assets Overview</h3>
        <ReloadButton onClick={reloadAll} className={classes.reloadButton} />
      </div>
      <div className={classes.container}>
        <div className={classes.top}>
          <div>
            <div className={classes.balanceLgTitle}>L2 Total Asset Value</div>
            <SingleLineSkeleton
              loading={loadingAssets || loadingStrategies || !investmentValue}
              height={theme.fontSizeXL}
            >
              <div className={classes.balanceLg}>
                {formatDecimal(investmentValue.add(BigNumber.from(token.amount || 0)), token.decimal)} {token.symbol}
              </div>
            </SingleLineSkeleton>
          </div>
          {isMobile ? (
            <div className={classes.valueDetail}>
              {!showValueDetail ? (
                <AngleCustom direction="down" style={{ fontSize: 20 }} onClick={toggleValueDetail} />
              ) : (
                <AngleCustom direction="up" style={{ fontSize: 20 }} onClick={toggleValueDetail} />
              )}
            </div>
          ) : (
            <div className={classes.topRight}>
              <div className={classes.balanceSmTitle}>L1 Balance in Wallet</div>
              <SingleLineSkeleton
                loading={token.symbol === "ETH" ? ethBalLoading : tokenBalLoading}
                height={theme.fontSizeL}
              >
                <div className={classes.balanceSm}>
                  {formatDecimal(l1Balance, token.decimal)} {token.symbol}
                </div>
              </SingleLineSkeleton>
            </div>
          )}
        </div>

        <div className={classes.bottom}>
          {(!isMobile || showValueDetail) && [
            <div className={classes.balanceUnit} key="1">
              <LabelWithPopover label={<span className={classes.balanceSmTitle}>Available L2 balance</span>}>
                This is your L2 balance available to commit to DeFi strategies or withdraw to your L1 wallet. You can
                increase your L2 balance by depositing from your L1 wallet or withdrawing funds from your portfolio
                strategies.
              </LabelWithPopover>
              <SingleLineSkeleton loading={loadingAssets} height={theme.fontSizeL}>
                <div className={classes.balanceSm}>
                  {formatDecimal(token.amount, token.decimal)} {token.symbol}
                </div>
              </SingleLineSkeleton>
            </div>,
            <div className={classes.balanceUnit} key="2">
              <LabelWithPopover label={<span className={classes.balanceSmTitle}>Investment Asset Value</span>}>
                This is the total asset value underlying the strategies you have invested and the farming sessions you
                have staked in. Note that the value is estimated based on your current stToken balance/price and may not
                be accurate.
              </LabelWithPopover>
              <SingleLineSkeleton loading={loadingAssets || loadingStrategies} height={theme.fontSizeL}>
                <div className={classes.balanceSm}>
                  {formatDecimal(investmentValue, token.decimal)} {token.symbol}
                </div>
              </SingleLineSkeleton>
            </div>,
            <div className={classes.balanceUnit} key="3">
              <LabelWithPopover label={<span className={classes.balanceSmTitle}>Total Earning</span>}>
                <p>Total Earning = L2 Total Asset Value - Total deposit to L2 + Total withdrawal from L2</p>
                <p>
                  <b>Note 1:</b> Earning does not include farming rewards which are distributed separately.
                </p>
                <p>
                  <b>Note 2:</b> Earning may be negative due to inherent strategy APY fluctuation, staking/unstaking
                  fee, etc.
                </p>
                <p>
                  <b>Note 3:</b> Earning is updated every {process.env.REACT_APP_EARNING_UPDATE_INTERVAL}.
                </p>
              </LabelWithPopover>
              <SingleLineSkeleton loading={loadingAssets || loadingStrategies} height={theme.fontSizeL}>
                <div className={classes.balanceSm}>
                  <TokenGain formattedAmount={formatDecimal(totalEarning, token.decimal)} symbol={token.symbol} />
                </div>
              </SingleLineSkeleton>
            </div>,
          ]}
          {isMobile && (
            <div style={{ marginTop: 24 }}>
              <div className={classes.balanceSmTitle}>L1 Balance in Wallet</div>
              <SingleLineSkeleton
                loading={token.symbol === "ETH" ? ethBalLoading : tokenBalLoading}
                height={theme.fontSizeL}
              >
                <div className={classes.balanceLg}>
                  {formatDecimal(l1Balance, token.decimal)} {token.symbol}
                </div>
              </SingleLineSkeleton>
            </div>
          )}
          <div className={classes.buttonGroup} style={{ marginTop: isMobile ? 24 : 0 }}>
            <Row style={{ width: "calc(100% + 14px)" }} gutter={14}>
              <Col span={12}>
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={handleDepositClick}
                  disabled={loadingAssets}
                  loading={checkingDepositLimit}
                  size="large"
                >
                  Deposit to L2
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  type="primary"
                  icon={<UploadOutlined />}
                  onClick={toggleWithdraw}
                  disabled={loadingAssets || eq(token.amount, 0)}
                  size="large"
                >
                  Withdraw to L1
                </Button>
              </Col>
            </Row>
          </div>
        </div>

        <ActionModal visible={!!error} onCancel={() => setError("")} onAction={() => setError("")}>
          {error}
        </ActionModal>

        {showDeposit && depositModal}
        {showWithdraw && <WithdrawModal token={token} onClose={toggleWithdraw} />}
      </div>
    </div>
  );
}
