import { ReloadOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
import classNames from "classnames";
import { FC } from "react";
import { createUseStyles } from "react-jss";
import { Theme } from "../theme/theme";

const useStyles = createUseStyles((theme: Theme) => ({
  button: {
    background: "#444648",
    "&.ant-btn": {
      border: "none",
      padding: 4,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      "&:focus, &:hover": {
        color: theme.fontColorSecondary,
      },
    },
  },
}));

const ReloadButton: FC<ButtonProps> = props => {
  const { children, className, ...restProps } = props;
  const classes = useStyles();
  return (
    <Button
      className={classNames(classes.button, className)}
      icon={<ReloadOutlined style={{ fontSize: 19, lineHeight: 1, height: 19 }} />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    >
      {children}
    </Button>
  );
};

export default ReloadButton;
