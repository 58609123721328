import { Card, Empty } from "antd";
import { createUseStyles, useTheme } from "react-jss";
import tokens from "../constants/tokens";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { saveShow } from "../redux/tokenToggleSlice";
import { Theme, ThemeType } from "../theme/theme";
import { AngleCustom } from "./customIcons";
import StrategyTable from "./StrategyTable";
import TokenDetail from "./TokenDetail";

const useStyles = createUseStyles((theme: Theme) => ({
  investHeader: {
    display: "flex",
    alignItems: "center",
  },
  tokenText: {
    fontSize: theme.fontSizeXL,
  },
  empty: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {},
  top: {
    marginBottom: 8,
    overflow: "hidden",
    borderTopRightRadius: theme.borderRadiusS,
  },
  divider: {
    margin: 0,
  },
  tag: {
    padding: "4px 12px",
    display: "inline",
    borderRadius: 11,
    background: "black",
    marginLeft: 12,
  },
  totlaCount: {
    height: 50,
    width: "100%",
    background: "rgba(255, 255, 255, 0.1)",
    borderRadius: 12,
    textAlign: "center",
    lineHeight: "50px",
  },

  totlaCountTitle: {
    fontSize: 14,
    color: "#fff",
    display: "inline-block",
  },
  totlaCountNum: {
    fontSize: 16,
    color: "#00D395",
    display: "inline-block",
    paddingLeft: 5,
  },
  tokenSelector: {
    margin: "24px 0",
    display: "flex",
  },
  selectpic: {
    width: 24,
    height: 24,
    borderRadius: "50%",
    "& img": {
      width: "100%",
      borderRadius: "50%",
    },
  },
  selectdes: {
    marginLeft: 5,
    marginRight: 10,
  },
}));

export default function InvestDetail(): JSX.Element {
  const classes = useStyles();
  const { asset } = useAppSelector(state => state);
  const { assets, selectedIndex } = asset;
  const token = assets[selectedIndex];
  const dispatch = useAppDispatch();
  const theme = useTheme<Theme>();
  const isMobile = theme.type === ThemeType.S;
  let tokenName = token?.name;
  // Special case WETH
  if (token?.symbol === "WETH") {
    tokenName = "ETH";
  }
  if (!token) {
    return (
      <div className={classes.empty}>
        <Empty className={classes.empty} image={null} description="Please select a token" />
      </div>
    );
  }

  const tokenSelector = (
    <div className={classes.tokenSelector} onClick={() => dispatch(saveShow({ IsShow: true }))}>
      <div className={classes.selectpic}>
        <img src={token.symbol && tokens[token.symbol].iconUrl} alt="" />
      </div>
      <div className={classes.selectdes}>{tokenName}</div>
      <div>
        <AngleCustom direction="down" style={{ fontSize: 20 }} />
      </div>
    </div>
  );

  const content = (
    <>
      <div className={classes.top}>
        <TokenDetail />
      </div>
      <StrategyTable />
    </>
  );

  return !isMobile ? (
    <Card className={classes.card} bodyStyle={{ padding: isMobile ? "32px 16px" : 0 }}>
      {content}
    </Card>
  ) : (
    <div>
      {tokenSelector}
      {content}
    </div>
  );
}
