import _ from "lodash";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { components } from "../../api/api";
import { Strata } from "../../constants/zIndex";
import { formatDecimal } from "../../helpers/format";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { fetchFarmingHistory } from "../../redux/farmingHistorySlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { Theme, ThemeType } from "../../theme/theme";
import { ClockCustom } from "../customIcons";
import CustomTable from "../CustomTable";
import LabelWithPopover from "../LabelWithPopover";
import Loading from "../Loading";
import PageFlipper from "../PageFlipper";
import TokenGain from "../TokenGain";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    margin: theme.hismainMargin,
    "@global": {
      "tbody .ant-table-cell": {
        paddingTop: 11,
        paddingBottom: 11,
      },
    },
  },
  refreshButton: {
    position: "absolute",
    background: theme.bgColorPrimary,
    right: 10,
    top: 16,
  },
  mainText: {
    fontSize: theme.fontSizeL,
  },
  subText: {
    color: theme.fontColorSecondary,
    fontSize: theme.fontSizeS,
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
  },
  mainchainHistory: {
    position: "relative",
  },
  reloadbtn: {
    position: "absolute",
    top: -125,
    right: 0,
    zIndex: Strata.LOW,
  },
  historyList: {},
  historyListItem: {
    borderBottom: "1px solid #808080",
  },
  itemOne: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 20,
  },
  ListItemLeft: {
    textAlign: "left",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  ListItemRight: {
    textAlign: "right",
    fontSize: "16px",
    color: "#fff",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  itemTop: {
    // lineHeight: 1,
  },
  itemTopname: {
    fontSize: "14px",
  },
  itemTopdesp: {
    fontSize: "14px",
    color: "#fff",
    lineHeight: 1,
  },
  itemTopval: {
    fontSize: "14px",
    color: "#00D395",
    marginLeft: 8,
    lineHeight: 1,
  },
  itemBottom: {
    fontSize: "12px",
    color: "#c4c4c4",
    marginTop: 4,
    lineHeight: 1,
  },
}));

export default function FarmingHistory(): JSX.Element {
  const classes = useStyles();
  const { address } = useWeb3Context();
  const { entries, hasMore, loading, currentPage } = useAppSelector(state => state.farmingHistory);
  const dispatch = useAppDispatch();
  const theme = useTheme<Theme>();
  const isMobile = theme.type === ThemeType.S;

  const fetchPage = useCallback(
    (page: number) => {
      if (!address) {
        return;
      }
      dispatch(fetchFarmingHistory({ address, page }));
    },
    [address, dispatch],
  );

  useEffect(() => {
    fetchPage(0);
  }, [fetchPage]);

  const mapDataToCols = useCallback((entry: components["schemas"]["FarmingHistoryEntry"]) => {
    return {
      key: entry.tnId,
      title: entry.strategyName,
      action: {
        action: entry.action,
        amount: formatDecimal(entry.shareAmt, entry.shareDecimal),
        symbol: entry.shareName,
      },
      reward: entry.rewardAssets,
      fee: {
        feeAmt: formatDecimal(entry.feeTokenAmt, entry.feeDecimal),
        feeToken: entry.feeTokenName,
      },
      status: {
        status: entry.status,
        time: entry.commitTimestamp && entry.commitTimestamp !== 0 ? entry.commitTimestamp : entry.acceptTimestamp,
      },
    };
  }, []);
  const changeStatus = useCallback(
    record => {
      const epoch = _.toNumber(record.status.time);
      let timeStr = "--";
      if (epoch !== 0) {
        timeStr = new Date(epoch).toLocaleString();
      }
      let statusText: string | ReactNode;
      switch (record.status.status) {
        case "PENDING":
        case "COMPLETED":
          statusText = "Completed";
          break;
        default:
          statusText = "Error";
      }
      return (
        <div>
          <div style={{ fontWeight: "normal" }}>{statusText}</div>
          <div className={classes.subText}>{timeStr}</div>
        </div>
      );
    },
    [classes.subText],
  );
  type Cols = ReturnType<typeof mapDataToCols>;

  const columns = useMemo(
    () => [
      {
        title: "Strategy",
        dataIndex: "title",
        render: (title: string) => {
          return <span className={classes.mainText}>{title}</span>;
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        render: ({ amount, symbol, action }: Cols["action"]) => {
          // eslint-disable-next-line no-nested-ternary
          const actionText = action === "STAKE" ? "Stake" : action === "UNSTAKE" ? "Unstake" : "Claim Reward";

          return (
            <div>
              <div style={{ fontWeight: "normal" }}>{actionText}</div>
              {action !== "CLAIM_REWARD" ? <div className={classes.subText}>{amount + " " + symbol}</div> : ""}
            </div>
          );
        },
      },
      {
        title: "Reward",
        dataIndex: "reward",
        render: (rewards: Cols["reward"]) => {
          return (
            <div>
              {rewards && rewards.length > 0
                ? rewards.map(reward => {
                    if (reward.amount) {
                      const formatedAmt = formatDecimal(reward.amount, reward.assetDecimal);
                      return (
                        <div key={reward.assetId}>
                          <TokenGain formattedAmount={formatedAmt} symbol={reward.assetSymbol} />
                        </div>
                      );
                    }
                    return "";
                  })
                : "--"}
            </div>
          );
        },
      },
      {
        title: (
          <LabelWithPopover label="Fee" placement="topLeft">
            There is a processing fee for each transaction in order to cover the operation cost of Layer2.Finance.
          </LabelWithPopover>
        ),
        dataIndex: "fee",
        render: (e, record) => {
          if (record.fee.feeAmt === "0.0") {
            return "--";
          }

          return record.fee.feeAmt + " " + record.fee.feeToken;
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (e, record) => changeStatus(record),
      },
    ],
    [changeStatus, classes],
  );

  const mobileList = entries ? entries.map(mapDataToCols) : [];

  const actionStatusText = {
    STAKE: "Stake",
    UNSTAKE: "Unstake",
    CLAIM_REWARD: "Claim Reward",
  };

  return (
    <div className={classes.container}>
      {isMobile ? (
        <div className={classes.mainchainHistory}>
          <Loading
            loading={loading}
            emptyIcon={<ClockCustom />}
            emptyDescription="No farming history yet"
            isEmpty={!entries?.length}
          >
            <div className={classes.historyList}>
              {mobileList.map(item => {
                return (
                  <div className={classes.historyListItem} key={item.key}>
                    <div className={classes.itemOne}>
                      <div className={classes.ListItemLeft}>
                        <div className={classes.itemTop}>
                          <span className={classes.itemTopname}>{item.title}</span>
                        </div>
                      </div>
                      <div className={classes.ListItemRight}>{changeStatus(item)}</div>
                    </div>
                    <div className={classes.itemOne}>
                      <div className={classes.ListItemLeft}>
                        <div className={classes.itemTop}>
                          <span className={classes.itemTopdesp}>Action</span>
                        </div>
                      </div>
                      <div className={classes.ListItemRight}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            flexDirection: "column",
                            fontSize: 12,
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ fontWeight: "normal" }}>
                            {item.action.action ? actionStatusText[item.action.action] : "UNKNOWN"}
                          </div>
                          {item.action.action !== "CLAIM_REWARD" ? (
                            <div className={classes.subText}>{item.action.amount + " " + item.action.symbol}</div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={classes.itemOne} style={{ marginBottom: 25 }}>
                      <div className={classes.ListItemLeft}>
                        <div className={classes.itemTop}>
                          <span className={classes.itemTopdesp}>Reward</span>
                        </div>
                      </div>
                      <div className={classes.ListItemRight}>
                        {(() => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: 12,
                                justifyContent: "flex-end",
                              }}
                            >
                              {item.reward && item.reward.length > 0
                                ? item.reward.map(reward => {
                                    if (reward.amount) {
                                      const formatedAmt = formatDecimal(reward.amount, reward.assetDecimal);
                                      return (
                                        <div>
                                          <TokenGain formattedAmount={formatedAmt} symbol={reward.assetSymbol} />
                                        </div>
                                      );
                                    }
                                    return "";
                                  })
                                : "--"}
                            </div>
                          );
                        })()}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Loading>
          {currentPage !== undefined ? (
            <div className={classes.pagination}>
              <PageFlipper page={currentPage} hasMore={hasMore} onPageChange={(toPage: number) => fetchPage(toPage)} />
            </div>
          ) : null}
        </div>
      ) : (
        <Loading
          loading={loading}
          emptyIcon={<ClockCustom height={20} width={20} />}
          emptyDescription="No farming history yet"
          isEmpty={!entries?.length}
        >
          <CustomTable
            dataSource={entries ? entries.map(mapDataToCols) : []}
            columns={columns}
            loading={loading}
            currentPage={currentPage}
            hasMore={hasMore}
            onPageChange={(toPage: number) => fetchPage(toPage)}
          />
        </Loading>
      )}
    </div>
  );
}
