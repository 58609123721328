import { FundOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, Select, Switch } from "antd";
import classNames from "classnames";
import React, { FC, useEffect, useMemo, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Link } from "react-router-dom";
import { FarmingPoolStatus } from "../api/farming";
import { SortBy, usePoolFilter } from "../hooks/poolFilter";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import { FarmingPool, fetchPools } from "../redux/farmingPoolSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { Theme, ThemeType } from "../theme/theme";
import FarmingPoolCard from "./FarmingPoolCard";
import Loading from "./Loading";
import { ClaimModal, StakeModal, UnstakeModal } from "./modals";

const useStyles = createUseStyles((theme: Theme) => {
  return {
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: 40,
      padding: "24px 12px",
      flexDirection: theme.type === ThemeType.S ? "column" : "row",
    },
    headerInfo: {
      marginRight: theme.type === ThemeType.S ? 0 : 12,
      marginBottom: theme.type === ThemeType.S ? 16 : undefined,
    },
    filters: {
      display: "flex",
      flexDirection: theme.type === ThemeType.S ? "column" : "row",
      width: theme.type === ThemeType.S ? "100%" : undefined,
      fontWeight: theme.fontWeightBold,
      fontSize: theme.fontSizeM,
    },
    filterRight: {
      display: "flex",
    },
    item: {
      display: "flex",
      flexDirection: "column",
      minWidth: 100,
      "&:not(:last-child)": {
        marginBottom: 12,
        marginRight: theme.type === ThemeType.S ? 0 : 12,
      },
    },
    itemLabel: {
      marginBottom: 6,
    },
    filterRightItem: {
      "&:not(:last-child)": {
        marginRight: 12,
      },
    },
    searchField: {
      flex: theme.type === ThemeType.S ? 1 : undefined,
    },
    content: {
      minHeight: 40,
      display: "flex",
      alignItems: "center",
    },
    showStakedField: {
      flexDirection: theme.type === ThemeType.S ? "row" : "column",
      alignItems: theme.type === ThemeType.S ? "center" : undefined,
      justifyContent: theme.type === ThemeType.S ? "space-between" : undefined,
      "& $itemLabel": {
        marginBottom: theme.type === ThemeType.S ? 0 : undefined,
      },
    },
    bottom: {
      margin: "0 -12px",
    },
    cardContainer: {
      width: theme.type === ThemeType.S ? "100%" : "33.3%",
    },
    cardsContainer: {
      display: "flex",
    },
    search: {
      fontSize: theme.fontSizeM,
      "&.ant-select > .ant-select-selector": {
        borderRadius: theme.borderRadiusS,
        background: theme.bgColorSubtle,
      },
    },
    input: {
      fontSize: theme.fontSizeM,
      "&.ant-input-affix-wrapper": {
        borderRadius: theme.borderRadiusS,
        background: theme.bgColorSubtle,
      },
    },
  };
});

interface IProps {
  status: FarmingPoolStatus;
}

interface IFormFieldProps {
  label?: string;
  className?: string;
}

/*
 * subcomponents
 */
const FormField: FC<IFormFieldProps> = props => {
  const { children, label, className } = props;
  const classes = useStyles();
  return (
    <div className={classNames(classes.item, className)}>
      <span className={classes.itemLabel}>{label}</span>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

const FarmingPools: FC<IProps> = props => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const { status } = props;

  /*
   * chain states
   */
  const { address, signer } = useWeb3Context();

  /*
   * redux states
   */
  const dispatch = useAppDispatch();
  const { pools, loading } = useAppSelector(state => state.farmingPools);

  /*
   * modal states
   */
  const [stakeModalVisible, setStakeModalVisible] = useState(false);
  const [unstakeModalVisible, setUnstakeModalVisible] = useState(false);
  const [claimModalVisible, setClaimModalVisible] = useState(false);
  const [selectedPool, setSelectedPool] = useState<FarmingPool>();

  /*
   * filter states
   */
  const { filteredPools, filters } = usePoolFilter(pools, status);

  useEffect(() => {
    dispatch(fetchPools(address));
  }, [dispatch, address, status]);

  if (pools.length && !selectedPool) {
    setSelectedPool(pools[0]);
  }

  const handleStake = (pool: FarmingPool) => {
    setSelectedPool(pool);
    setStakeModalVisible(true);
  };
  const handleUnstake = (pool: FarmingPool) => {
    setSelectedPool(pool);
    setUnstakeModalVisible(true);
  };
  const handleClaim = (pool: FarmingPool) => {
    setSelectedPool(pool);
    setClaimModalVisible(true);
  };

  const handleModalClose = (shouldReload = false) => {
    if (shouldReload) {
      dispatch(fetchPools(address));
    }
    setStakeModalVisible(false);
    setUnstakeModalVisible(false);
    setClaimModalVisible(false);
  };

  const poolCards = useMemo(() => {
    if (!filteredPools) {
      return null;
    }
    // display three cards per row if screen is wide enough, otherwise display 1 per row
    let chunkSize = 3;
    if (theme.type === ThemeType.S) {
      chunkSize = 1;
    }
    const chunked: FarmingPool[][] = [];
    for (let i = 0; i < filteredPools.length; i += chunkSize) {
      const chunk: FarmingPool[] = [];
      for (let j = 0; j < chunkSize; j++) {
        const pool = filteredPools[i + j];
        if (!pool) {
          break;
        }
        chunk.push(filteredPools[i + j]);
      }
      chunked.push(chunk);
    }
    return chunked.map(chunk => {
      return (
        <div
          className={classes.cardsContainer}
          key={chunk.reduce((key, { id, strategyName }) => key + id + strategyName, "")}
        >
          {chunk.map(pool => (
            <div className={classes.cardContainer} key={pool.id}>
              <FarmingPoolCard
                disabled={!signer}
                pool={pool}
                poolStatus={status}
                onStake={handleStake}
                onUnStake={handleUnstake}
                onClaim={handleClaim}
              />
            </div>
          ))}
        </div>
      );
    });
  }, [filteredPools, theme.type, classes, signer, status]);

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.headerInfo}>
          {status === "ACTIVE" ? (
            <span>
              In case you don’t see your stake, please check the <Link to="/farming/ended">Ended</Link> section.
            </span>
          ) : (
            <span>
              Layer2.Finance has launched new farming programs for strategies. Check our Docs about the migration to new
              farming contracts.
            </span>
          )}
        </div>
        <div className={classes.filters}>
          <FormField label="Show Staked" className={classes.showStakedField}>
            <Switch style={{ width: 44 }} checked={filters.showStaked} onChange={filters.setShowStaked} />
          </FormField>
          <div className={classes.filterRight}>
            <FormField label="Sort By" className={classes.filterRightItem}>
              <Select
                size="large"
                className={classes.search}
                defaultActiveFirstOption
                style={{ width: 167 }}
                value={filters.sortBy}
                onChange={filters.setSortBy}
              >
                <Select.Option value={SortBy.STRATEGY_NAME}>Strategy Name</Select.Option>
                <Select.Option value={SortBy.FARMING_APY}>Farming APY</Select.Option>
                <Select.Option value={SortBy.TOTAL_STAKED}>Total Staked</Select.Option>
              </Select>
            </FormField>
            <FormField label="Search" className={classNames(classes.filterRightItem, classes.searchField)}>
              <Input
                className={classes.input}
                size="large"
                placeholder="Search Farms"
                prefix={<SearchOutlined />}
                value={filters.search}
                onChange={filters.setSearch}
              />
            </FormField>
          </div>
        </div>
      </div>
      <div className={classes.bottom}>
        <Loading
          loading={loading}
          emptyIcon={pools?.length ? <SearchOutlined /> : <FundOutlined />}
          emptyDescription={pools?.length ? "Oops... No result found." : ""}
        >
          {poolCards}
        </Loading>
      </div>
      {selectedPool ? (
        <>
          <StakeModal visible={stakeModalVisible} pool={selectedPool} onClose={handleModalClose} />
          <UnstakeModal visible={unstakeModalVisible} pool={selectedPool} onClose={handleModalClose} />
          <ClaimModal visible={claimModalVisible} pool={selectedPool} onClose={handleModalClose} />
        </>
      ) : null}
    </div>
  );
};

export default FarmingPools;
