export enum TnType {
  INVALID,
  Init,
  Deposit,
  Withdraw,
  Buy,
  Sell,
  XferAsset,
  XferShare,
  AggOrder,
  ExeResult,
  Settle,
  // fee
  WithdrawFee,
  XferFee,

  // Staking
  Stake,
  Unstake,
  AddPool,
  UpdatePool,
  DepositReward,
}
