import { ColumnsType, ColumnType } from "antd/lib/table";
import { PropsWithChildren, useCallback } from "react";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createUseStyles } from "react-jss";
import { Theme } from "../theme/theme";

interface IProps<T> {
  dataList: T[];
  columns: ColumnsType<T>;
  keyPropName: string;
}
const useStyles = createUseStyles((theme: Theme) => ({
  tableList: {
    padding: 16,
    paddingTop: 0,
    position: "relative",
    background: theme.contentBackground,
    borderRadius: theme.borderRadiusXL,
    marginBottom: 16,
  },
  tableListItem: {
    display: "flex",
    alignItems: "center",
    minHeight: 66,
    justifyContent: "space-between",
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.borderColorPrimary}`,
    },
  },
  tableListItemLeft: {},
  tableListItemRight: {
    fontWeight: "bold",
  },
  tableListItemBtn: {
    width: "100%",
    paddingTop: 10,
  },
}));

const ContentList = <T,>(props: PropsWithChildren<IProps<T>>) => {
  const classes = useStyles();
  const { dataList, columns, keyPropName } = props;

  const renderRowValue = useCallback((column: ColumnType<T>, record: T, index: number) => {
    return typeof column?.render === "function" && column?.render("", record, index);
  }, []);

  return (
    <div>
      {dataList?.map(record => {
        return (
          <div className={classes.tableList} key={record[keyPropName]}>
            {columns.map((column, i) => {
              return (
                <div className={classes.tableListItem} key={column.key}>
                  {column.title ? (
                    <>
                      <div className={classes.tableListItemLeft}>{column.title}</div>
                      <div className={classes.tableListItemRight}>{renderRowValue(column, record, i)}</div>
                    </>
                  ) : (
                    <div className={classes.tableListItemBtn}>{renderRowValue(column, record, i)}</div>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
export default ContentList;
