import { BigNumber, BigNumberish } from "ethers";

export function compareBigNumbers(num1: BigNumberish, num2: BigNumberish) {
  const diff = BigNumber.from(num1).sub(num2);
  if (diff.isZero()) {
    return 0;
  }
  if (diff.isNegative()) {
    return -1;
  }
  return 1;
}

const defaultToZero =
  <R>(fn: (num1: BigNumberish, num2: BigNumberish) => R) =>
  (num1?: BigNumberish, num2?: BigNumberish) => {
    let _num1 = num1;
    let _num2 = num2;

    // manually check because input param default "num1: Bignumber = 0" won't default null to 0
    if (!_num1) {
      _num1 = 0;
    }
    if (!_num2) {
      _num2 = 0;
    }

    return fn.apply(this, [_num1, _num2]);
  };

export const add = defaultToZero((num1: BigNumberish, num2: BigNumberish) => {
  return BigNumber.from(num1).add(num2);
});

export const sub = defaultToZero((num1: BigNumberish, num2: BigNumberish) => {
  return BigNumber.from(num1).sub(num2);
});

export const eq = defaultToZero((num1: BigNumberish, num2: BigNumberish) => {
  return BigNumber.from(num1).eq(num2);
});

export const gt = defaultToZero((num1: BigNumberish, num2: BigNumberish) => {
  return BigNumber.from(num1).gt(num2);
});

export const gte = defaultToZero((num1: BigNumberish, num2: BigNumberish) => {
  return BigNumber.from(num1).gte(num2);
});

export const lt = defaultToZero((num1: BigNumberish, num2: BigNumberish) => {
  return BigNumber.from(num1).lt(num2);
});

export const lte = defaultToZero((num1: BigNumberish, num2: BigNumberish) => {
  return BigNumber.from(num1).lte(num2);
});
